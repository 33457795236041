.div-block-121 {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
}

.logo-image {
	position: static;
	top: 0px;
	display: block;
	width: 100%;
}

.about-link {
	margin-left: 0rem;
	border-style: none;
	border-width: 1px;
	border-color: #fff;
	border-radius: 0px;
	background-color: transparent;
	transition: background-color 400ms ease;
	text-decoration: none;
}

.about-link:hover {
	border-style: none;
	border-color: #c41100;
	background-color: transparent;
}

.search-input {
	width: 700px;
	margin-bottom: 0rem;
	margin-left: 0px;
	padding: 0.75rem 1.25rem 0.75rem 0rem;
	border-style: none;
	border-width: 1px 1px 2px;
	border-color: #000 #000 #4d4d4d;
	border-radius: 0px;
	background-color: transparent;
	font-family: 'Open Sans', sans-serif;
	color: #4d4d4d;
	font-size: 0.85rem;
	font-weight: 400;
}

.contact-link {
	display: flex;
	height: auto;
	margin-right: 0rem;
	margin-left: 1rem;
	padding-right: 0rem;
	padding-left: 0rem;
	align-items: center;
	border-style: none;
	border-width: 1px;
	border-color: #fff rgba(33, 33, 33, 0.1);
	border-radius: 0px;
	background-color: transparent;
	transition: background-color 400ms ease;
	text-decoration: none;
}

.contact-link:hover {
	border-style: none;
	border-color: #000;
	background-color: transparent;
}

.navbar {
	position: static;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	z-index: 999;
	display: block;
	width: 100%;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
	background-color: transparent;
}

.mobile-nav-link {
	display: none;
	padding-top: 20px;
	padding-bottom: 5px;
	padding-left: 0px;
	transition: all 200ms ease;
	color: #196ab5;
	font-size: 27px;
	line-height: 30px;
	font-weight: 700;
	letter-spacing: 0px;
}

.mobile-nav-link:hover {
	color: #ca4949;
}

.mega-col {
	display: flex;
	width: 11%;
	padding: 0rem 0.75rem;
	flex-direction: column;
	justify-content: center;
	flex-wrap: nowrap;
	align-items: center;
	flex: 0 auto;
	border-style: none;
	border-width: 1px;
	border-color: #000 rgba(82, 82, 82, 0.9) #000 #000;
	text-align: center;
}

.mega-col.first-dropdown-link {
	padding: 0rem 0.6rem;
	justify-content: center;
	flex-wrap: nowrap;
	-ms-grid-row-align: center;
	align-self: center;
	flex: 1;
	border-right-style: none;
	border-right-color: rgba(82, 82, 82, 0.9);
	text-align: center;
}

.mega-col.lastb-dropdown-link {
	width: 9%;
	padding: 0rem 0.5rem;
	justify-content: flex-start;
	flex-wrap: nowrap;
	-ms-grid-row-align: center;
	align-self: center;
	flex: 1;
	border-right-style: none;
	border-right-color: rgba(82, 82, 82, 0.08);
	text-align: center;
}

.nav-drop-list-content {
	display: flex;
	width: 1500px;
	max-width: none;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
	border-bottom: 1px none rgba(0, 0, 0, 0.07);
}

.nav-drop-list-content.full_megamenu {
	align-items: center;
	-ms-grid-row-align: center;
	align-self: center;
}

.two-part-button-wrap {
	position: relative;
	display: block;
	max-width: 768px;
	margin-right: auto;
	margin-left: auto;
}

.online-banking-login-button {
	position: static;
	display: none;
	margin-left: 20px;
	padding: 0.5rem 0.75rem 0.5rem 1rem;
	justify-content: space-between;
	align-items: center;
	border-style: solid;
	border-width: 1px;
	border-color: #aa1e47;
	border-radius: 5px;
	background-color: #941114;
	transition: background-color 300ms ease;
	font-family: 'Open Sans', sans-serif;
	font-size: 0.9rem;
	line-height: 0.9rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
}

.online-banking-login-button:hover {
	background-color: #c33b3e;
	opacity: 1;
}

.logo {
	position: static;
	left: 20%;
	top: 36.5469px;
	bottom: 0px;
	z-index: 99;
	display: flex;
	width: 180px;
	margin-top: 0px;
	justify-content: center;
	align-items: center;
	align-self: flex-end;
	flex: 0 auto;
	background-color: transparent;
}

.dropdown-mega-menu {
	position: absolute;
	left: 0px;
	top: 85px;
	right: 0px;
	z-index: 10000;
	display: none;
	padding: 1.5rem 4rem 2.5rem;
	justify-content: center;
	flex-wrap: wrap;
	background-color: #fafafa;
}

.search {
	display: block;
	padding-top: 0rem;
	padding-bottom: 0.5rem;
	padding-left: 0rem;
	justify-content: center;
	align-items: center;
	align-self: flex-end;
	order: 1;
}

.search-bar-wrap {
	position: absolute;
	left: 0%;
	top: 165px;
	right: 0%;
	bottom: auto;
	z-index: 0;
	display: flex;
	width: 45%;
	height: 60px;
	margin-right: auto;
	margin-bottom: 0px;
	margin-left: auto;
	padding-right: 1.5rem;
	padding-left: 1.5rem;
	justify-content: center;
	align-items: center;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	background-color: #fafafa;
	box-shadow: 1px 1px 4px 0 rgba(82, 82, 82, 0.15);
	opacity: 0;
	transform: translate(0px, -80px);
	font-family: Lato, sans-serif;
	color: #fff;
}

.main-nav-dropdown-wrapper {
	display: flex;
	padding-top: 0rem;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
}

.mega-menu-row {
	display: flex;
	width: 100%;
	max-width: 1450px;
	flex-direction: row;
	justify-content: flex-start;
}

.mega-menu-row.first-row {
	max-width: none;
	margin-bottom: 0rem;
	padding-right: 0px;
	padding-left: 0px;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
	align-content: stretch;
}

.news-link {
	display: flex;
	height: auto;
	margin-right: 0rem;
	margin-left: 1rem;
	padding-right: 0rem;
	padding-left: 0rem;
	align-items: center;
	border-style: none;
	border-width: 1px;
	border-color: #fff rgba(33, 33, 33, 0.1);
	border-radius: 0px;
	background-color: transparent;
	transition: background-color 400ms ease;
	text-decoration: none;
}

.news-link:hover {
	border-style: none;
	border-color: #000;
	background-color: transparent;
}

.nav-menu {
	position: relative;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	align-self: flex-end;
}

.dropdown-link {
	margin-bottom: 0rem;
	padding-top: 13px;
	padding-bottom: 13px;
	color: #333;
	font-size: 0.9rem;
	line-height: 0.95rem;
	font-weight: 600;
	text-align: left;
	text-decoration: none;
}

.dropdown-link:hover {
	background-color: #001e45;
	color: #fff;
}

.search-icon {
	width: 35px;
	height: 35px;
	min-height: auto;
	margin-right: 16px;
	margin-left: 16px;
	padding: 0rem 0.25rem;
	border: 0.25px none transparent;
	border-radius: 5px;
	background-color: transparent;
	background-image: url('/20181016082423/assets/images/search-icon-gray-01.svg');
	background-position: 50% 50%;
	background-size: 15px;
	background-repeat: no-repeat;
	transition: all 300ms ease, background-color 300ms ease;
}

.search-icon:hover {
	border-style: none;
	border-color: #fff;
	border-radius: 5px;
	background-color: #941114;
	background-image: url('/20181016082423/assets/images/search-white-01.svg');
	background-position: 50% 50%;
	background-size: 15px;
	background-repeat: no-repeat;
}

.sub-mega-menu {
	display: flex;
	width: 9%;
	padding: 0rem 0.75rem;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	flex: 1;
	border-right: 1px none rgba(82, 82, 82, 0.9);
	text-align: center;
}

.main-navbar-wrapper {
	position: static;
	top: 60px;
	z-index: 4;
	display: flex;
	width: 100%;
	height: 85px;
	padding-top: 0rem;
	padding-bottom: 0rem;
	padding-left: 0px;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-end;
	border-bottom: 1px none #ececec;
	box-shadow: none;
}

.online-banking-icon {
	margin-right: auto;
	padding-right: 0.75rem;
}

.link-text {
	margin-right: 0px;
	padding-left: 0px;
	border-bottom: 1px none rgba(255, 255, 255, 0.35);
	opacity: 1;
	transition: opacity 300ms ease;
	color: #fff;
	font-size: 1rem;
	line-height: 0.95rem;
	font-weight: 400;
	letter-spacing: 0px;
	text-decoration: none;
	text-transform: none;
}

.link-text:hover {
	border-bottom: 2px none #941114;
	opacity: 1;
	color: #fff;
	text-decoration: underline;
}

.link-text.banking-login-button {
	opacity: 1;
	font-family: 'Open Sans', sans-serif;
	line-height: 0.9rem;
}

.link-text.banking-login-button:hover {
	border-bottom-style: none;
}

.link-text._2 {
	border-bottom: 2px none transparent;
	font-size: 0.875rem;
}

.link-text._2:hover {
	border-bottom-style: none;
	border-bottom-color: #941114;
}

.link-text._3 {
	border-bottom: 2px none transparent;
	font-size: 0.875rem;
}

.link-text._3:hover {
	border-bottom-style: none;
	border-bottom-color: #941114;
}

.link-text._1 {
	border-bottom: 2px none transparent;
	font-size: 0.875rem;
}

.link-text._1:hover {
	border-bottom-style: none;
	border-bottom-color: #941114;
}

.main-nav-dropdown-link {
	color: #941114;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 700;
	text-decoration: none;
}

.main-nav-dropdown-link:hover {
	color: #1c3d66;
}

.mobile-nav-link-dropdown {
	display: inline-block;
	margin-right: 1px;
	padding: 1rem 0.5rem;
	flex: 1 0 auto;
	box-shadow: none;
	color: #000;
	font-size: 1.25rem;
	line-height: 1.375rem;
	font-weight: 800;
	text-align: center;
	text-transform: uppercase;
}

.mobile-nav-link-dropdown:hover {
	background-color: transparent;
	box-shadow: inset 0 -3px 0 0 #3128b1;
	color: #3128b1;
}

.mobile-nav-link-dropdown.w--current {
	background-color: rgba(0, 0, 0, 0.2);
	box-shadow: 0 -3px 0 0 #fff;
	color: #fff;
}

.mobile-nav-link-dropdown.w--current:hover {
	background-color: rgba(0, 0, 0, 0.1);
	color: #fff;
}

.nav-link-wrapper {
	display: flex;
	padding: 0rem 10px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: center;
	flex: 0 auto;
	border-bottom: 3px solid transparent;
}

.nav-link-wrapper:hover {
	border-bottom-color: #941114;
}

.nav-link {
	position: static;
	width: 100%;
	margin-left: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
	color: #333;
	font-size: 1rem;
	line-height: 1.15rem;
	letter-spacing: 0px;
	text-decoration: none;
	text-transform: none;
	text-align: center;
}

.nav-link:hover {
	color: #941114;
}

.nav-top-header-wrapper {
	position: static;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	z-index: 6;
	display: flex;
	height: 40px;
	padding-top: 0.65rem;
	padding-right: 5%;
	padding-left: 3rem;
	justify-content: center;
	align-items: flex-end;
}

.search-input-exit {
	display: block;
	width: 25px;
	margin-right: 0rem;
	margin-bottom: 0rem;
	margin-left: auto;
	padding-right: 0px;
	padding-left: 0px;
	background-color: transparent;
	background-image: url('/20181016082423/assets/images/exit-icon.svg');
	background-position: 50% 50%;
	background-size: 12px 12px;
	background-repeat: no-repeat;
	margin-right: -7px;
	padding: 20px;
}

.search-input-exit.two {
	display: none;
}

.scroll-arrow {
	position: absolute;
	left: auto;
	top: -20px;
	right: auto;
	bottom: auto;
	margin-right: auto;
	margin-left: auto;
	padding: 0.25rem;
	border-radius: 5px;
	background-color: rgba(255, 255, 255, 0.2);
	opacity: 0;
}

.slider-h2-heading {
	max-width: none;
	margin-top: 0px;
	margin-bottom: 0.5rem;
	font-family: Montserrat, sans-serif;
	color: #001e45;
	font-size: 2.5rem;
	line-height: 3rem;
	font-weight: 900;
	letter-spacing: 0.05rem;
	text-transform: none;
}

.slide-nav {
	font-size: 0.8rem;
}

.slider-contents-wrapper {
	position: absolute;
	left: 0px;
	top: 15vh;
	display: flex;
	width: 60%;
	height: 550px;
	padding: 4rem 3rem 4rem 15%;
	flex-direction: column;
	align-items: flex-start;
	border-radius: 5px;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
}

.primary-button {
	display: inline-block;
	margin-top: 0rem;
	margin-right: 1rem;
	padding: 0.65rem 1rem;
	border-radius: 5px;
	background-color: #001e45;
	transition: all 300ms ease, box-shadow 300ms ease, transform 300ms ease, background-color 300ms ease;
	color: #fff;
	font-size: 0.9rem;
	line-height: 0.95rem;
	font-weight: 600;
	letter-spacing: 0px;
	text-decoration: none;
	text-transform: none;
}

.primary-button:hover {
	background-color: #941114;
	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.27);
}

.left-arrow-icon {
	max-width: 1600px;
}

.slider-text {
	margin-bottom: 10px;
	font-family: Montserrat, sans-serif;
	color: #001e45;
	font-size: 1.15rem;
	line-height: 1.25rem;
	font-weight: 400;
}

.slide-2 {
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.scroll-down-text {
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.85rem;
	font-weight: 600;
	text-align: center;
}

.slideshow {
	top: 115px;
	height: 70vh;
	margin-top: 0px;
	min-height: 500px;
}

.icon-5 {
	top: 140px;
	display: flex;
	width: 2.75rem;
	height: 2.75rem;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	background-color: rgba(255, 255, 255, 0.2);
	transition: transform 300ms ease, background-color 300ms ease;
	color: #fff;
	font-size: 1.5rem;
	line-height: 1.75rem;
}

.icon-5:hover {
	background-color: rgba(28, 61, 102, 0.9);
	transform: scale(1.035);
}

.slider-container {
	position: relative;
	top: 0px;
	z-index: 33;
	display: flex;
	height: 100%;
	max-width: none;
	padding-right: 8rem;
	padding-left: 12rem;
	align-items: center;
}

.right-arrow-icon {
	max-width: 1600px;
}

.scroll-arrow-holder {
	position: absolute;
	left: 0%;
	top: auto;
	right: 0%;
	bottom: 5%;
	z-index: 100;
	display: flex;
	width: 100%;
	height: 50px;
	margin-top: 15px;
	justify-content: center;
}

.scroll-down-arrow {
	margin-top: 10px;
	transform: rotate(90deg);
	color: #fff;
	font-size: 0.75rem;
	line-height: 0.75rem;
	font-weight: 700;
}

.home-content-section {
	position: static;
	left: auto;
	top: auto;
	display: flex;
	overflow: hidden;
	width: auto;
	height: auto;
	max-width: none;
	margin-bottom: 0rem;
	margin-left: 0px;
	padding: 4rem;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: transparent;
}

.home-content-section.quick-links-section {
	position: relative;
	top: 190px;
	margin-top: -77px;
	padding: 3rem 11%;
	background-color: #fff;
	background-image: none;
	background-size: auto;
	font-family: Montserrat, sans-serif;
}

.home-content-section.newsletter {
	overflow: hidden;
	padding: 5rem 4rem 4rem;
	background-color: #941114;
	background-image: url('/20181016082423/assets/images/star-white-svg.svg');
	background-position: 100% 50%;
	background-size: 300px;
	background-repeat: no-repeat;
}

.home-content-section.newsletter {
	position: relative;
	top: 190px;
	overflow: hidden;
	padding: 4rem;
	background-color: #d3d6d8;
	background-image: url('/20181016082423/assets/images/star-white-svg.svg');
	background-position: 100% 50%;
	background-size: 500px;
	background-repeat: no-repeat;
	color: #ddddd8;
}

.h3-headings {
	display: inline-block;
	max-width: 500px;
	margin-top: 1rem;
	margin-right: 0rem;
	margin-bottom: 0rem;
	padding-right: 0rem;
	border-right: 5px none #3eedb6;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	font-family: 'Open Sans', sans-serif;
	color: #1c3d66;
	font-size: 1.1rem;
	line-height: 1rem;
	font-weight: 700;
	text-align: center;
	letter-spacing: 0px;
	text-indent: 0px;
	text-transform: none;
}

.h3-headings.icon-headings {
	max-width: none;
	margin-bottom: 1.25rem;
	border-right-style: none;
	font-family: Montserrat, sans-serif;
	color: #001e45;
	letter-spacing: 0rem;
}

.icon-img {
	padding-top: 0px;
	opacity: 1;
}

.icon-wrap {
	display: flex;
	overflow: hidden;
	width: 6rem;
	height: 6rem;
	margin-bottom: 0.5rem;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	background-color: transparent;
}

.icon-link-wrapper {
	display: flex;
	height: auto;
	padding-top: 0rem;
	padding-bottom: 0rem;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: 1px solid transparent;
	background-color: transparent;
	text-decoration: none;
	cursor: pointer;
}

.quick-links-wrapper {
	display: flex;
	width: 80%;
	height: 100%;
	flex-direction: column;
	align-items: center;
}

.icon-wrapper {
	display: flex;
	width: 100%;
	height: 100%;
	margin-top: 0rem;
	margin-bottom: 0rem;
	justify-content: space-between;
	align-items: center;
}

.hero-slide-overlay {
	position: absolute;
	left: -77.9vw;
	top: 0px;
	z-index: 10;
	display: none;
	width: 100vw;
	height: 100%;
	background-color: rgba(0, 30, 69, 0.47);
	-webkit-backdrop-filter: blur(5px);
	backdrop-filter: blur(5px);
	transform: skew(51deg, 0deg);
}

.hero-slide-overlay-copy {
	position: absolute;
	left: 0px;
	top: 0px;
	right: auto;
	z-index: 10;
	width: 100vw;
	height: 100%;
	background-position: 0% 50%;
	background-size: 700px;
	background-repeat: no-repeat;
	opacity: 1;
	filter: saturate(200%);
}

.slider-copy {
	width: 35vw;
	padding: 25px;
	background-color: rgba(255, 255, 255, 0.32);
	-webkit-backdrop-filter: blur(17px);
	backdrop-filter: blur(17px);
}

.slide-2-copy {
	background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 8%, hsla(0, 0%, 100%, 0.13) 33%), url('/20181016082423/assets/images/image-slideshow-2.jpg');
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.newsletter-form {
	display: flex;
	max-width: none;
	margin: 0rem 0px 0px;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex: 0 auto;
}

.heading {
	margin-top: 0px;
	margin-bottom: 0.5rem;
	color: #1c3d66;
	font-size: 2.65rem;
	line-height: 3rem;
	font-style: normal;
	font-weight: 700;
	text-align: left;
	letter-spacing: 0.05rem;
}

.heading.white {
	color: #fff;
	line-height: 3rem;
	text-align: left;
}

.heading.white {
	font-family: Montserrat, sans-serif;
	color: #001e45;
	font-size: 2.5rem;
	line-height: 3rem;
	text-align: left;
}

.newsletter-cb-wrapper {
	display: flex;
	width: 100%;
	max-width: 1450px;
	flex-direction: column;
	align-items: center;
}

.heading-wrapper {
	display: flex;
	margin-bottom: 0rem;
	flex-direction: column;
	align-items: center;
}

.text-block {
	max-width: 750px;
	margin-bottom: 1rem;
	color: #333;
	font-size: 1rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-align: left;
}

.text-block.centered {
	margin-bottom: 1rem;
}

.text-block.centered.white {
	color: #fff;
	text-align: center;
}

.text-block.centered {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	font-family: Montserrat, sans-serif;
	color: #001e45;
	font-weight: 500;
}

.text-block.centered.white {
	margin-bottom: 0rem;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-weight: 500;
	text-align: left;
}

.primary-button-2 {
	display: inline-block;
	margin-top: 2rem;
	margin-right: 1rem;
	padding: 0.65rem 1rem;
	border-radius: 5px;
	background-color: #aa1e47;
	transition: all 300ms ease, box-shadow 300ms ease, transform 300ms ease, background-color 300ms ease;
	color: #fff;
	font-size: 0.9rem;
	line-height: 0.95rem;
	font-weight: 600;
	letter-spacing: 0px;
	text-decoration: none;
	text-transform: none;
}

.primary-button-2:hover {
	background-color: #bb355c;
	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.27);
}

.primary-button-2.subscribe {
	margin-top: 0rem;
	background-color: #001e45;
}

.primary-button-2.subscribe:hover {
	background-color: #265894;
}

.primary-button-2.subscribe {
	margin-top: 0rem;
	background-color: #001e45;
}

.primary-button-2.subscribe:hover {
	background-color: #265894;
}

.newsletter-form-cb {
	display: flex;
	width: auto;
	min-width: 400px;
	flex-direction: row;
	justify-content: flex-start;
	flex-wrap: nowrap;
	align-items: center;
}

.newsletter-content-section {
	display: flex;
	overflow: visible;
	width: 100%;
	justify-content: center;
	align-items: center;
}

.newsletter-text-field {
	height: auto;
	margin-right: 1rem;
	margin-bottom: 0rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	border-style: solid;
	border-width: 1px;
	border-color: #ececec;
	border-radius: 5px;
	background-color: #fff;
	font-family: 'Open Sans', sans-serif;
	color: #9d2a2a;
	font-size: 0.8rem;
	font-weight: 400;
}

.horiz-heading-underline {
	width: 75px;
	height: 2px;
	margin-bottom: 1.75rem;
	background-color: #1c3d66;
}

.horiz-heading-underline.white {
	margin-bottom: 1.5rem;
	background-color: #fff;
}

.horiz-heading-underline.white {
	width: 100%;
	height: 10px;
	margin-bottom: 0rem;
	background-color: #941114;
}

.parallax-wrapper {
	position: relative;
	top: 190px;
	font-size: 1.2em;
}

.block-wrapper {
	width: 100%;
	height: 100%;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
}

.block-wrapper.parallax {
	display: flex;
	max-width: none;
	min-height: 500px;
	justify-content: center;
	background-image: linear-gradient(180deg, rgba(32, 60, 128, 0.5), rgba(32, 60, 128, 0.5)), url('/20181016082423/assets/images/AdobeStock_296159541.jpeg');
	background-position: 0px 0px, 100% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
	background-attachment: scroll, fixed;
}

.block-wrapper.parallax.block-padding {
	background-position: 0px 0px, 50% 100%;
}

.block-wrapper.horz-flex {
	display: flex;
	padding-right: 2rem;
	padding-left: 2rem;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.parallax-content-wrapper {
	display: flex;
	width: 100%;
	margin-top: 2rem;
	margin-bottom: 2rem;
	justify-content: center;
	align-self: center;
}

.cta-block {
	display: flex;
	width: 75%;
	padding: 3rem;
	align-items: center;
	border-radius: 5px;
	background-color: rgba(255, 255, 255, 0.85);
}

.flourish {
	position: relative;
	display: flex;
	width: 100%;
	padding-bottom: 0.5rem;
	justify-content: center;
	color: #001e45;
	margin-bottom: 10px;
	font-weight: 700;
}

.div-block-122 {
	display: none;
	width: 5px;
	height: 50px;
	margin-right: 20px;
	margin-left: 20px;
	background-color: #941114;
	color: #941114;
}

.div-block-122.red {
	display: block;
	width: 10px;
	height: 150px;
}

.paragraph-2 {
	margin-bottom: 20px;
	font-family: Montserrat, sans-serif;
	font-size: 1.05rem;
	line-height: 22px;
}

.footer-content-wrapper {
	display: flex;
	width: 100%;
	margin-right: 2rem;
	margin-bottom: 1rem;
	margin-left: 2rem;
	padding-bottom: 1rem;
	flex-direction: row;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;
	border-bottom: 2px none #002f6b;
}

.image-links {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex: 0 auto;
}

.footer-block {
	position: relative;
	top: 190px;
	padding-top: 1rem;
	padding-bottom: 2rem;
	background-color: #001e45;
}

.footer-block.inside {
	top: 0px;
}

.links-container {
	display: flex;
	width: 25%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
}

.footer-links {
	display: flex;
	height: auto;
	max-width: 1280px;
	margin-right: auto;
	margin-bottom: 1rem;
	margin-left: auto;
	padding-top: 1rem;
	padding-bottom: 1rem;
	padding-left: 0px;
	justify-content: center;
	flex-wrap: wrap;
	align-items: center;
	grid-column-gap: 1rem;
	grid-row-gap: 1rem;
	border-bottom: 2px none #002f6b;
}

.footer-links-container {
	background-color: #001e45;
}

.wr-social {
	display: flex;
	margin-right: 10px;
	grid-column-gap: 0.5rem;
}

.social-link {
	display: flex;
	width: 35px;
	height: 35px;
	justify-content: center;
	align-items: center;
	flex: 0 0 auto;
	border-radius: 5px;
	background-color: transparent;
	transition: transform 200ms ease;
	color: #fdfdfc;
	line-height: 1;
	text-align: center;
}

.social-link:hover {
	border-style: none;
	border-color: #9daad4;
	border-radius: 3px;
	opacity: 1;
	transform: scale(1.1);
	color: #941114;
}

.img-social {
	width: 25px;
	height: 25px;
}

.img-social:hover {
	color: #941114;
}

.footer-grip-link {
	width: 133px;
	height: 29px;
	min-width: 0px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding-left: 33px;
	background-image: url('/20181016082423/assets/images/globalreach_dark.svg');
	background-position: 50% 50%;
	background-size: 130px 29px;
	background-repeat: no-repeat;
	transition: all 200ms ease;
	text-decoration: none;
	cursor: pointer;
}

.footer-grip-link.white-logo {
	width: 140px;
	margin-top: 0rem;
	margin-right: 0rem;
	margin-bottom: 0rem;
	background-image: url('/20181016082423/assets/images/grip_logo_white-webdevelopment.svg');
	background-size: 150px 29px;
}

.copyright {
	display: flex;
	width: 100%;
	margin-right: 2rem;
	margin-left: 2rem;
	padding-top: 1rem;
	justify-content: space-between;
	align-items: center;
}

.wr-copyright-content {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	grid-column-gap: 1rem;
}

.footer-text {
	margin-top: 0px;
	margin-right: 0px;
	margin-left: 0px;
	padding: 0px;
	flex-direction: column;
	justify-content: flex-start;
	flex: 0 auto;
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.4;
	font-weight: 400;
	text-transform: capitalize;
}

.footer-text h1 {
	display: inline;
	font-size: inherit;
	color: inherit;
	font-family: inherit;
	line-height: inherit;
	margin: 0px;
	padding: 0px;
}


.footer-link {
	color: #fffefe;
	line-height: 1rem;
	font-weight: 400;
	text-decoration: none;
}

.footer-link:hover {
	background-color: transparent;
	color: #d4a359;
}

.footer-link.footer-link-item {
	padding-top: 0rem;
	padding-bottom: 0rem;
	color: #d3d6d8;
	font-size: 0.875rem;
	line-height: 1.4;
	font-weight: 400;
	text-decoration: underline;
}

.footer-link.footer-link-item:hover {
	background-color: transparent;
	opacity: 1;
	color: #fff;
}

.housing-logos {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	flex: 0 auto;
}

.ncua-logo {
	display: block;
	width: 80px;
	height: 50px;
	margin-right: auto;
	margin-left: auto;
	background-image: url('/20181016082423/assets/images/NCUA.png');
	background-position: 50% 50%;
	background-size: 70px;
	background-repeat: no-repeat;
	text-align: center;
}

.ehl-logo {
	display: block;
	width: 80px;
	height: 50px;
	background-image: url('/20181016082423/assets/images/Equal-Housing.svg');
	background-position: 50% 50%;
	background-size: 50px;
	background-repeat: no-repeat;
}

.div-block-123 {
	display: flex;
	width: 35%;
	padding-top: 0.5rem;
	flex-direction: column;
	align-items: center;
}

.link {
	color: #fff;
	text-decoration: none;
}

.cb-static {
	width: 33.33%;
	padding-top: 1rem;
	padding-bottom: 1rem;
}

.cb-static.footer-cb-left {
	display: flex;
	width: auto;
	margin-right: 0rem;
	padding-top: 0rem;
	padding-right: 0rem;
	padding-bottom: 0rem;
	flex-direction: column;
	align-items: stretch;
}

.cb-heading {
	display: inline-block;
	margin-top: 0rem;
	margin-bottom: 1rem;
	color: #fff;
	font-size: 2.286rem;
	line-height: 2.986rem;
	font-weight: 400;
}

.cb-heading.footer-heading {
	display: block;
	margin-bottom: 0.25rem;
	border-bottom: 2px none rgba(255, 255, 255, 0.2);
	color: #fff;
	font-size: 1.05rem;
	line-height: 2.5rem;
	font-weight: 700;
	letter-spacing: 0rem;
	text-transform: none;
}

.cb-heading.footer-heading-copy {
	display: block;
	margin-top: 1rem;
	margin-bottom: 0rem;
	border-bottom: 2px none rgba(255, 255, 255, 0.2);
	color: #fff;
	font-size: 1.05rem;
	line-height: 2.5rem;
	font-weight: 700;
	letter-spacing: 0rem;
	text-transform: none;
}

.footer-nav-list {
	margin-top: 0rem;
	font-size: 0.9rem;
	line-height: 1.65rem;
}

.footer-nav-item {
	margin-top: 0rem;
	padding-top: 0.75rem;
	color: #fff;
	font-size: 0.7rem;
	line-height: 0.9rem;
	letter-spacing: 0.1em;
	text-transform: uppercase;
}

.footer-list-item-link {
	padding-left: 0rem;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	opacity: 1;
	transition: color 300ms ease;
	color: #fff;
	font-size: 1rem;
	line-height: 0.9rem;
	font-weight: 400;
	letter-spacing: 0em;
	text-decoration: none;
	text-transform: capitalize;
}

.footer-list-item-link:hover {
	border-bottom: 1px none #888;
	background-image: none;
	opacity: 1;
	color: #fff;
	text-decoration: underline;
}

.footer-list-item-link.w--current {
	border-bottom: 1px solid #888;
	color: #f9b918;
}

.div-block-124 {
	width: 100%;
	height: 2px;
	margin-bottom: 1rem;
	background-color: #002f6b;
}

.div-block-125 {
	padding-left: 10px;
}

.card-wrapper {
	padding-top: 2rem;
	padding-bottom: 2rem;
	background-color: rgba(94, 89, 95, 0.15);
}

.card-wrapper.block-padding {
	position: relative;
	top: 190px;
	padding-top: 3rem;
	padding-bottom: 3rem;
	background-color: #ddddd8;
}

.card-block-wrapper {
	display: -ms-grid;
	display: grid;
	max-width: 1000px;
	justify-content: center;
	justify-items: center;
	align-items: center;
	grid-auto-columns: 1fr;
	grid-column-gap: 4.75rem;
	grid-row-gap: 31px;
	-ms-grid-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	-ms-grid-rows: 1fr 1fr;
	grid-template-rows: 1fr 1fr;
}

.card {
	flex-direction: column;
	align-items: center;
	grid-row-gap: 1rem;
	border-bottom: 3px solid transparent;
	transition: transform 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
	text-align: center;
	text-decoration: none;
}

.card:hover {
	border-bottom: 3px solid #941114;
	transform: translate(0px, -15px);
}

.card-title {
	display: inline-block;
	margin-top: 20px;
	margin-bottom: 0px;
	justify-content: center;
	align-items: center;
	font-family: 'Open Sans', sans-serif;
	color: #001e45;
	font-size: 1rem;
	line-height: 1;
	text-align: center;
}

._4cards-wrapper {
	display: flex;
	width: 80%;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	justify-content: center;
	align-items: flex-start;
}

.card-image {
	width: 300px;
	height: 300px;
	border-radius: 5px;
	background-image: url('/20181016082423/assets/images/AdobeStock_548505698_Preview.jpeg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.card-image:hover {
	box-shadow: 1px 1px 6px -4px #000;
}

.card-image._2 {
	background-image: url('/20181016082423/assets/images/AdobeStock_170520365_Preview.jpeg');
}

.card-image._3 {
	background-image: url('/20181016082423/assets/images/AdobeStock_291631606_Preview.jpeg');
	background-position: 100% 50%;
}

.card-image._4 {
	border-radius: 5px;
	background-image: url('/20181016082423/assets/images/AdobeStock_338035931_Preview.jpeg');
}

.logo-mobile {
	position: absolute;
	left: 10%;
	bottom: 0px;
	display: none;
	width: 165px;
	height: 165px;
	max-width: 200px;
	min-width: auto;
	justify-content: center;
	align-items: center;
	flex: 0 auto;
	background-color: #fff;
}

.online-banking-login-mobile-button {
	position: static;
	display: none;
	margin-left: 20px;
	padding: 0.5rem 0.75rem 0.5rem 1rem;
	justify-content: space-between;
	align-items: center;
	border-style: solid;
	border-width: 1px;
	border-color: #aa1e47;
	border-radius: 5px;
	background-color: #941114;
	transition: background-color 300ms ease;
	font-family: 'Open Sans', sans-serif;
	font-size: 0.9rem;
	line-height: 0.9rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
}

.online-banking-login-mobile-button:hover {
	background-color: #c33b3e;
	opacity: 1;
}

.online-banking-login-mobile-button-copy {
	position: static;
	display: flex;
	margin-left: 20px;
	padding: 0.5rem 0.75rem 0.5rem 1rem;
	justify-content: space-between;
	align-items: center;
	border-style: solid;
	border-width: 1px;
	border-color: #aa1e47;
	border-radius: 5px;
	background-color: #941114;
	transition: background-color 300ms ease;
	font-family: 'Open Sans', sans-serif;
	font-size: 0.9rem;
	line-height: 0.9rem;
	font-weight: 400;
	text-decoration: none;
	text-transform: none;
}

.online-banking-login-mobile-button-copy:hover {
	background-color: #c33b3e;
	opacity: 1;
}

.text-block-2 {
	color: #fff;
}

.div-block-126 {
	display: flex;
	align-items: center;
	font-size: 16px;
	line-height: 22px;
}

.link-text-copy {
	margin-right: 0px;
	padding-left: 5px;
	border-bottom: 1px none rgba(255, 255, 255, 0.35);
	opacity: 1;
	transition: opacity 300ms ease;
	color: #fff;
	font-size: 1rem;
	line-height: 0.95rem;
	font-weight: 400;
	letter-spacing: 0px;
	text-decoration: none;
	text-transform: none;
}

.link-text-copy:hover {
	border-bottom: 2px none #941114;
	opacity: 1;
	color: #fff;
	text-decoration: underline;
}

.text-block-3 {
	color: #fff;
}

.text-span {
	font-size: 1.05rem;
}

.text-span strong {
	font-weight: 700;
}

.div-block-127 {
	position: static;
	display: flex;
	margin-top: 0.5rem;
	padding-right: 0px;
	padding-left: 0px;
	align-items: center;
}

.kutup-dropdown-link {
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	border-bottom: 1px solid rgba(51, 51, 51, 0.12);
	color: #333;
	font-size: 14px;
	font-weight: 700;
}

.kutup-dropdown-link:hover {
	background-color: rgba(51, 51, 51, 0.05);
	color: #941114;
}

.kutup-dropdown-list {
	position: static;
	display: none;
	margin-left: 0px;
	padding: 2px;
	justify-content: center;
}

.kutup-dropdown-list.w--open {
	left: -78px;
	top: 85%;
	right: auto;
	bottom: auto;
	display: block;
	width: 100%;
	margin: 0px 0px 6px;
	padding: 16px;
	justify-content: center;
	border-style: solid;
	border-width: 3px 1px 1px;
	border-color: #941114 #eeece9 #eeece9;
	border-radius: 0px 0px 5px 5px;
	background-color: #fff;
}

.kutup-dropdown-arrow {
	display: none;
	margin-right: 0px;
	color: #333;
	font-size: 12px;
	font-weight: 300;
}

.kutup-dropdown-toggle {
	padding: 2rem 30px 2rem 15px;
	color: #264653;
	font-size: 17px;
	font-weight: 600;
}

.kutup-dropdown-toggle:hover {
	color: rgba(38, 70, 83, 0.8);
}

.nav-link-copy {
	display: none;
	margin-right: 1.5rem;
	margin-left: 1.5rem;
	padding: 2rem 0rem;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 0.95rem;
	line-height: 1.15rem;
	font-weight: 700;
	letter-spacing: 0px;
	text-decoration: none;
	text-transform: none;
}

.nav-link-copy:hover {
	color: #941114;
}

.text-block-4 {
	color: #333;
	font-size: 0.95rem;
	font-weight: 700;
}

.text-block-4:hover {
	color: #941114;
}

.kutup-dropdown-list-copy {
	padding: 2px;
}

.kutup-dropdown-list-copy.w--open {
	left: 207px;
	top: -5.6094px;
	margin-top: 6px;
	margin-bottom: 6px;
	margin-left: 0px;
	padding: 16px;
	border: 1px none #eeece9;
	border-radius: 0px 5px 5px;
	background-color: #001e45;
	font-size: 14px;
}

.kutup-dropdown-link-copy {
	padding-top: 10px;
	padding-bottom: 10px;
	color: #fff;
	font-size: 16px;
	font-weight: 700;
}

.kutup-dropdown-link-copy:hover {
	color: #941114;
}

.kutup-dropdown-link-copy.white {
	padding: 10px 0px;
	color: #fff;
	font-size: 14px;
}

.kutup-dropdown-link-copy.white:hover {
	color: #fff;
	text-decoration: underline;
}

.kutup-dropdown-toggle-copy {
	display: block;
	width: 100%;
	padding-top: 10px;
	padding-right: 30px;
	padding-bottom: 0px;
	border-bottom: 1px solid rgba(51, 51, 51, 0.12);
	color: #264653;
	font-size: 17px;
	font-weight: 600;
}

.kutup-dropdown-toggle-copy:hover {
	background-color: rgba(51, 51, 51, 0.05);
	color: rgba(38, 70, 83, 0.8);
}

.kutup-dropdown-toggle-copy.w--open {
	padding-top: 0rem;
	padding-bottom: 0rem;
}

.kutup-dropdown-arrow-copy {
	display: block;
	margin-right: 12px;
	color: #333;
	font-size: 12px;
	font-weight: 300;
}

.kutup-dropdown-link-copy {
	padding-top: 0px;
	padding-bottom: 0px;
	border-bottom: 1px none rgba(51, 51, 51, 0.12);
	color: #333;
	font-size: 14px;
	font-weight: 700;
}

.kutup-dropdown-link-copy:hover {
	color: #941114;
}

.kutup-dropdown-toggle-copy {
	padding: 2rem 30px 2rem 15px;
	color: #264653;
	font-size: 17px;
	font-weight: 600;
}

.kutup-dropdown-toggle-copy:hover {
	color: rgba(38, 70, 83, 0.8);
}

.nav_megamenu.w--open {
	position: absolute;
	left: 0%;
	top: 100%;
	right: 0%;
	bottom: auto;
	display: flex;
	overflow: hidden;
	width: 100vw;
	max-width: 100%;
	min-width: 100vw;
	padding-right: 5%;
	padding-left: 5%;
	justify-content: center;
	border-bottom: 1px none #000;
	background-color: #fff;
	-o-object-fit: cover;
	object-fit: cover;
}

.nav_megamenu.full_megamenu {
	left: 0%;
	top: 100%;
	right: 0%;
	bottom: auto;
}

.nav_megamenu.full_megamenu.w--open {
	left: auto;
	top: 96%;
	right: 0px;
	margin-right: -116px;
	padding-top: 18px;
	padding-bottom: 18px;
	justify-content: center;
	border-top: 3px solid #941114;
}

.rl-dropdown-icon {
	position: absolute;
	top: 0%;
	right: 0%;
	bottom: 0%;
	display: none;
	width: 1rem;
	height: 1rem;
	margin-top: auto;
	margin-right: 1rem;
	margin-bottom: auto;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.rl_navbar5_dropdown-toggle {
	position: static;
	padding: 2rem 2.5rem 2rem 1rem;
	font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	color: #000;
	font-size: 1rem;
	line-height: 1.5;
	font-weight: 400;
	text-decoration: none;
}

.nav-megamenu {
	position: static;
	display: none;
	margin-right: 0px;
	margin-left: auto;
}

.nav-link-wrapper-copy {
	display: flex;
	padding: 0rem 17px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	align-self: stretch;
	border-bottom: 3px solid transparent;
}

.nav-link-wrapper-copy:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
}

.kutup-dropdown-arrow-copy {
	margin-right: 12px;
	color: #333;
	font-size: 12px;
	font-weight: 300;
}

.dropdown {
	position: static;
}

.dropdown-list {
	left: 0%;
	top: 100%;
	right: 0%;
	bottom: auto;
	z-index: 101;
	width: 80%;
	margin-top: 3px;
	padding: 15px;
	background-color: #fff;
}

.dropdown-list.w--open {
	position: fixed;
	top: auto;
	display: flex;
	width: 80%;
	min-width: auto;
	margin-right: auto;
	margin-left: auto;
	padding: 16px 32px;
	justify-content: center;
	align-items: center;
	background-color: #f2f2f2;
}

.dropdown-toggle {
	height: 100%;
}

.nav-menu-mob {
	position: static;
	display: none;
	width: 100%;
	margin-top: 0px;
	justify-content: flex-end;
	align-items: flex-end;
}

.body {
	font-family: Montserrat, sans-serif;
}

.dropdown-toggle-2 {
	padding-top: 0px;
	padding-bottom: 0px;
}

.dropdown-list-2 {
	margin-top: 5px;
}

.dropdown-list-2.w--open {
	margin-top: -3px;
	margin-left: -27px;
	padding-top: 10px;
	padding-right: 0px;
	padding-left: 0px;
	background-color: #f4f4f4;
}

.dropdown-toggle-2-copy {
	padding-top: 0px;
	padding-bottom: 0px;
	padding-left: 0px;
}

.dropdown-toggle-2-copy.w--open {
	padding-left: 0px;
}

.dropdown-list-2-copy {
	margin-top: 5px;
}

.dropdown-list-2-copy.w--open {
	margin-top: 0px;
	margin-left: 0px;
	padding-top: 10px;
	padding-right: 0px;
	padding-left: 0px;
	background-color: #f4f4f4;
}

.heading-4 {
	margin-top: 0px;
	margin-bottom: 5px;
	color: #fff;
	font-weight: 600;
	text-align: left;
	text-transform: uppercase;
}

.online-banking-module {
	position: static;
	left: auto;
	top: 5%;
	right: 5%;
	z-index: 100;
	display: block;
	padding: 16px;
	flex-direction: column;
	order: 1;
}

.online-banking-module .online-banking-wrapper {
	position: relative;
}

.online-banking-module .online-banking-wrapper .header-login-button {
	background-color: #941114 !important;
	background-position: 20px 50%;
	background-repeat: no-repeat;
	background-size: 18px 18px;
	align-self: center;
	padding: 1rem 30px 1rem 30px;
	color: #fff;
	font-size: 14px;
	line-height: 20px;
	font-weight: bold;
	text-transform: uppercase;
	text-decoration: none;
	white-space: nowrap;
}

.online-banking-module .online-banking-wrapper .online-banking-form {
	background-color: #941114;
	position: absolute;
	top: 35px;
	left: 0px;
	z-index: 2000;
	display: none;
	max-width: 260px;
	padding: 1.55rem;
}

.online-banking-module .online-banking-wrapper .online-banking-form.slide-up-fade-in {
	display: unset !important;
	animation: slide-up-fade-in linear 500ms;
	animation-iteration-count: 1;
	transform-origin: 50% 50%;
	animation-fill-mode: forwards; /*when the spec is finished*/
	-webkit-animation: slide-up-fade-in linear 500ms;
	-webkit-animation-iteration-count: 1;
	-webkit-transform-origin: 50% 50%;
	-webkit-animation-fill-mode: forwards; /*Chrome 16+, Safari 4+*/
	-moz-animation: slide-up-fade-in linear 500ms;
	-moz-animation-iteration-count: 1;
	-moz-transform-origin: 50% 50%;
	-moz-animation-fill-mode: forwards; /*FF 5+*/
	-o-animation: slide-up-fade-in linear 500ms;
	-o-animation-iteration-count: 1;
	-o-transform-origin: 50% 50%;
	-o-animation-fill-mode: forwards; /*Not implemented yet*/
	-ms-animation: slide-up-fade-in linear 500ms;
	-ms-animation-iteration-count: 1;
	-ms-transform-origin: 50% 50%;
	-ms-animation-fill-mode: forwards; /*IE 10+*/
	opacity: 0;
	opacity: 1\9;
}

@keyframes slide-up-fade-in {
	0% {
		opacity: 0;
		transform: translate(0px, 60px)  ;
	}

	75% {
		opacity: 0.5;
		transform: translate(0px, 0px)  ;
	}

	100% {
		opacity: 1;
	}
}


.form-block {
	margin-bottom: 5px;
}

.submit-button {
	background-color: #001e45;
	font-weight: 600;
}

.text-field {
	margin-bottom: 0px;
}

.form {
	display: flex;
	align-items: center;
}

.login-link-copy {
	display: inline-block;
	padding-right: 0px;
	flex-direction: row;
	color: #fff;
	cursor: pointer;
}

.login-link {
	display: inline;
	padding-right: 20px;
	color: #fff;
	cursor: pointer;
}

._4cards-wrapper-2 {
	display: flex;
	height: 100%;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding-top: 5rem;
	justify-content: center;
	justify-items: center;
	grid-auto-columns: 1fr;
	grid-column-gap: 85px;
	grid-row-gap: 16px;
	-ms-grid-columns: 1fr;
	grid-template-columns: 1fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
}

.card-2 {
	flex-direction: column;
	align-items: center;
	grid-row-gap: 1rem;
	border-bottom: 3px solid transparent;
	transition: transform 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
	text-align: center;
	text-decoration: none;
}

.card-2:hover {
	border-bottom: 3px solid #941114;
	transform: translate(0px, -15px);
}

.card-image-2 {
	width: 500px;
	height: 300px;
	border-radius: 5px;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.card-image-2:hover {
	box-shadow: 1px 1px 6px -4px #000;
}

.card-image-2._2 {
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, repeat;
}

.card-image-2._3 {
	background-position: 0px 0px, 100% 50%;
	background-size: auto, cover;
	background-repeat: repeat, repeat;
}

.card-image-2._4 {
	border-radius: 5px;
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, repeat;
}

.card-title-2 {
	display: inline-block;
	margin-top: 20px;
	margin-bottom: 0px;
	padding-bottom: 32px;
	justify-content: center;
	align-items: center;
	color: #001e45;
	font-size: 1.5rem;
	line-height: 1;
	text-align: center;
}

.divider {
	width: 1px;
	height: 25px;
	background-color: rgba(82, 82, 82, 0.8);
	-o-object-fit: fill;
	object-fit: fill;
}

.call-box {
	display: flex;
	width: 100%;
	padding: 16px;
	justify-content: center;
	border-radius: 10px;
	background-color: #001e45;
	color: #001e45;
}

.newsletter-form-2 {
	display: flex;
	max-width: none;
	margin: 0rem 0px 0px;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex: 0 auto;
}

.subscribe-box {
	margin-top: 16px;
	padding: 16px;
	border-radius: 10px;
	background-color: #fff;
	color: #001e45;
}

.heading-2 {
	margin-top: 0px;
	margin-bottom: 0px;
	color: #fff;
	font-size: 38px;
	line-height: 44px;
}

.heading-wrapper-2 {
	display: flex;
	margin-bottom: 0rem;
	padding-right: 16px;
	padding-left: 16px;
	flex-direction: column;
	align-items: center;
}

.heading-wrapper-2._2nd {
	padding-left: 0px;
}

.div-block-131 {
	padding-top: 6px;
	padding-right: 32px;
	padding-left: 0px;
}

.newsletter-cb-wrapper-2 {
	display: flex;
	padding-top: 16px;
	padding-right: 15px;
	padding-bottom: 16px;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-start;
}

.div-block-132 {
	display: flex;
	width: 100%;
	padding: 16px 16px 0px;
	align-items: flex-end;
}

.newsletter-text-field-2 {
	height: auto;
	margin-right: 1rem;
	margin-bottom: 0rem;
	padding-top: 0.65rem;
	padding-bottom: 0.65rem;
	border-style: solid;
	border-width: 1px;
	border-color: #ececec;
	border-radius: 5px;
	background-color: #d3d6d8;
	font-family: 'Open Sans', sans-serif;
	color: #9d2a2a;
	font-size: 0.8rem;
	font-weight: 400;
	width: 384px;
}

.div-block-126-copy {
	display: flex;
	width: 50%;
	margin-left: 15px;
	padding: 16px;
	flex-direction: column;
	align-items: flex-start;
}

.newsletter-content-section-2 {
	display: flex;
	overflow: visible;
	justify-content: center;
	align-items: flex-end;
}

.div-block-133 {
	display: flex;
	padding-left: 16px;
	flex-direction: column;
	justify-content: center;
	font-family: Montserrat, sans-serif;
	text-align: left;
}

.disclosure {
	padding-top: 20px;
}

.paragraph-4 {
	font-size: 0.775rem;
	line-height: 20px;
	color: #fff;
	margin-top: 0;
	margin-bottom: 10px;
}

.html-embed-2 {
	display: block;
}

.nav-animation-trigger {
	width: 100%;
	background-color: #fff;
}

.header {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	z-index: 99;
	display: -ms-grid;
	display: grid;
	justify-items: center;
	grid-auto-columns: 1fr;
	grid-column-gap: 16px;
	grid-row-gap: 16px;
	-ms-grid-columns: 0.5fr 1.5fr 0.5fr;
	grid-template-columns: 0.5fr 1.5fr 0.5fr;
	-ms-grid-rows: auto auto;
	grid-template-rows: auto auto;
	border-bottom: 2px none #941114;
	background-color: #fff;
	background-image: url('/20181016082423/assets/images/new-blue-wave.svg');
	background-position: 100% 0%;
	background-size: 100%;
	background-repeat: no-repeat;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
}

.heading-6 {
	font-family: Montserrat, sans-serif;
	font-size: 2.5rem;
	font-weight: 700;
}

.main-content-section {
	max-width: none;
	margin-right: 0rem;
}

.main-content-section.right {
	padding-right: 1.5rem;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.main-content-wrapper {
	display: block;
	width: 95%;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.section-wrap {
	overflow: hidden;
	margin-top: 140px;
	background-color: #d3d6d8;
	background-image: none;
}

.section-wrap.full-grey {
	background-color: #d3d6d8;
	background-image: none;
}

.h4-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(92, 92, 92, 0.15);
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #162b6d;
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	text-decoration: none;
}

.h4-header-link:hover {
	border-bottom-width: 2px;
	border-bottom-color: transparent;
	background-color: transparent;
	font-family: Montserrat, sans-serif;
	color: #162b6d;
	font-weight: 400;
}

.div-block-140 {
	position: relative;
	overflow: hidden;
	width: 75%;
	height: 375px;
}

.div-block-140.inside-page {
	width: 100%;
	height: 250px;
}

.table-div {
	font-family: Montserrat, sans-serif;
	font-size: 0.8rem;
	font-weight: 400;
}

.breadcrumb-list {
	margin: 0rem 0.125rem 1rem;
	padding-left: 0px;
	font-size: 0.6875rem;
	line-height: 1em;
	text-transform: uppercase;
	margin-top: -8px;
}

.paragraph-12 {
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 1.2rem;
	line-height: 1.5rem;
	font-weight: 400;
}

.ordered-list-2 {
	margin-bottom: 1rem;
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	line-height: 1.5rem;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
}

.inside-content-beckground {
	position: relative;
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding: 3rem 15px 4rem;
	border-top: 1px none #000;
	background-color: #fff;
}

.horiz-divider-2 {
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: rgba(92, 92, 92, 0.15);
	color: #003c43;
}

.h3-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(92, 92, 92, 0.15);
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #162b6d;
	font-size: 24px;
	line-height: 30px;
	font-weight: 400;
	text-decoration: none;
}

.h3-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #162b6d;
}

.text-block-21 {
	font-family: Montserrat, sans-serif;
	color: #162b6d;
	font-size: 0.9rem;
	font-weight: 700;
	text-transform: capitalize;
}

.heading-10 {
	margin-bottom: 5px;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-weight: 400;
	text-transform: uppercase;
}

.heading-18 {
	margin-bottom: 5px;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-weight: 700;
}

.second-button {
	display: none;
	margin-bottom: 20px;
	padding-right: 60px;
	padding-left: 0px;
	background-color: transparent;
	background-image: url('/20181016082423/assets/images/blue-arrow-icon-01.svg');
	background-position: 90% 50%;
	background-size: 17px;
	background-repeat: no-repeat;
	transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-family: Montserrat, sans-serif;
	color: #162b6d;
	font-size: 0.9rem;
	font-weight: 700;
	letter-spacing: 0.5px;
}

.second-button:hover {
	background-position: 95% 50%;
}

.inside-page-background-image {
	position: relative;
	display: none;
	height: 200px;
	margin-right: -15px;
	margin-left: -15px;
	padding-top: 0px;
	padding-right: 0px;
	padding-left: 0px;
	flex-direction: column;
	justify-content: flex-end;
	align-items: flex-end;
	background-color: transparent;
	background-image: linear-gradient(180deg, rgba(22, 22, 22, 0.5), rgba(22, 22, 22, 0.5)), url('/20181016082423/assets/images/about-us-image-edited.png');
	background-position: 0px 0px, 50% 60%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.h2-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(92, 92, 92, 0.15);
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #162b6d;
	font-size: 2rem;
	line-height: 2.25rem;
	font-weight: 400;
	text-decoration: none;
}

.h2-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #162b6d;
}

.div-block-138 {
	height: 100%;
	background-image: url('/20181016082423/assets/images/GettyImages-1180123651.jpg');
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: no-repeat;
}

.div-block-138.recent-blog-post {
	width: 100%;
	height: 100%;
	background-image: url('/20181016082423/assets/images/tim-van-der-kuip-CPs2X8JYmS8-unsplash.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: repeat;
}

.h6-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(92, 92, 92, 0.15);
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #162b6d;
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
	text-decoration: none;
	text-transform: uppercase;
}

.h6-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #162b6d;
}

.tertiary-button {
	margin-left: 20px;
	padding: 0px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	background-color: transparent;
	transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-family: Montserrat, sans-serif;
	color: #006953;
	font-size: 0.9rem;
	line-height: 1.35rem;
	font-weight: 500;
	letter-spacing: 1px;
}

.tertiary-button:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	background-image: none;
	color: #006953;
}

.tertiary-button.style {
	color: #162b6d;
}

.link-5 {
	border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
	font-family: Montserrat, sans-serif;
	color: #162b6d;
	font-weight: 400;
	text-decoration: none;
}

.link-5:hover {
	border-bottom-style: none;
	border-bottom-color: #549fc4;
}

.link-5.w--current {
	color: #28313b;
	font-weight: 700;
}

.link-5.breadcrumb-link {
	border-bottom-color: rgba(51, 51, 51, 0.35);
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 0.9rem;
	font-weight: 500;
	text-decoration: none;
	text-transform: capitalize;
}

.link-5.breadcrumb-link:hover {
	border-bottom-style: none;
	border-bottom-color: rgba(9, 21, 248, 0.35);
	color: #162b6d;
}

.button-styling {
	display: flex;
	padding-left: 0px;
	align-items: center;
	font-family: 'Open Sans', sans-serif;
	font-weight: 800;
}

.intro-paragraph-2 {
	margin-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 1.25rem;
	line-height: 1.5rem;
	font-weight: 500;
}

.block-quote-2 {
	margin-top: 10px;
	margin-bottom: 20px;
	border-left-color: #941114;
	background-color: transparent;
	font-family: Montserrat, sans-serif;
	color: #5b5b5b;
	font-size: 1.3rem;
	line-height: 1.75rem;
	font-weight: 500;
}

.main-button {
	border-radius: 0px;
	background-color: #fff;
	transition: background-color 350ms ease;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 0.75rem;
	font-weight: 500;
	letter-spacing: 1px;
}

.main-button:hover {
	background-color: #162b6d;
	color: #fff;
}

.main-button.inside-page {
	margin-right: 20px;
	border-style: solid;
	border-width: 0.15px;
	border-color: rgba(51, 51, 51, 0.25);
}

.paragraph-14 {
	margin-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.5rem;
	text-decoration: none;
}

.paragraph-14.list-item {
	margin-bottom: 0rem;
}

.unordered-list {
	margin-bottom: 1rem;
}

.h5-header-link {
	display: inline-block;
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(92, 92, 92, 0.15);
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #162b6d;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	text-decoration: none;
	text-transform: uppercase;
}

.h5-header-link:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #162b6d;
}

.inside-page-header-content-wrap-2 {
	margin-bottom: 1.5rem;
	padding-bottom: 0.5rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}

.left-nav-nested-list-link {
	display: block;
	padding: 0.75em 1.5rem;
	border: 2px none transparent;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 0.85em;
	line-height: 1.375em;
	font-weight: 500;
	text-decoration: none;
	text-transform: capitalize;
}

.left-nav-nested-list-link:hover {
	border: 2px none #48bdd7;
	background-color: transparent;
	background-image: none;
	color: #162b6d;
}

.left-nav-nested-list-link.w--current {
	color: #5e8dbe;
}

.left-nav-nested-list-link.w--current:hover {
	color: #162b6d;
}

.left-nav-nested-list-link.great-grandchild {
	padding-left: 2.5rem;
	color: #941114;
	font-size: 0.825em;
}

.left-nav-nested-list-link.great-grandchild:hover {
	background-color: rgba(211, 214, 216, 0.3);
	color: #162b6d;
}

.left-nav-nested-list-link.grandchild {
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: repeat;
	transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	color: #941114;
}

.left-nav-nested-list-link.grandchild:hover {
	background-color: rgba(211, 214, 216, 0.3);
	background-image: url('/20181016082423/assets/images/blue-arrow-icon-01_1.svg');
	background-position: 0% 50%;
	background-size: 12px;
	background-repeat: no-repeat;
	transform: translate(5px, 0px);
	color: #162b6d;
}

.left-nav-nested-list-link.great-grandchild {
	padding-left: 2.5rem;
	color: #333;
	font-size: 0.825em;
}

.left-nav-nested-list-link.great-grandchild:hover {
	color: #162b6d;
}

.left-nav-nested-list-link.grandchild {
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: repeat;
	transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	color: 941114;
}

.left-nav-nested-list-link.grandchild:hover {
	background-image: url('/20181016082423/assets/images/blue-arrow-icon-01_1.svg');
	background-position: 0% 50%;
	background-size: 12px;
	background-repeat: no-repeat;
	transform: translate(5px, 0px);
	color: #162b6d;
}

.left-nav-list-item {
	margin-bottom: 0rem;
	box-shadow: none;
}

.left-nav-list-content-wrap {
	display: block;
	overflow: hidden;
	height: auto;
	margin-top: -1.5rem;
	margin-right: 1.5rem;
	padding-bottom: 1.5rem;
	border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.left-nav-list-link {
	display: block;
	padding: 0.75rem 1.5rem 0.75rem 1rem;
	border-bottom-style: none;
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: repeat;
	transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-family: Montserrat, sans-serif;
	color: #333;
	font-weight: 500;
	text-decoration: none;
	text-transform: capitalize;
}

.left-nav-list-link:hover {
	background-color: rgba(211, 214, 216, 0.3);
	background-image: url('/20181016082423/assets/images/blue-arrow-icon-01_1.svg');
	background-position: 0% 50%;
	background-size: 12px;
	background-repeat: no-repeat;
	transform: translate(5px, 0px);
	color: #162b6d;
	text-decoration: none;
}

.left-nav-list-link.w--current {
	background-color: transparent;
	background-image: url('/20181016082423/assets/images/blue-arrow-icon-01_1.svg');
	background-position: 0% 50%;
	background-size: 12px;
	background-repeat: no-repeat;
	color: #162b6d;
}

.content-container {
	display: block;
	width: 50%;
	max-width: none;
	margin-right: 0px;
	margin-left: 0px;
	padding-top: 0rem;
	padding-right: 0rem;
	padding-left: 0rem;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
	background-color: #fff;
}

.content-container.threequarterwidth {
	width: 100%;
	max-width: none;
	padding-right: 0rem;
	padding-left: 0rem;
}

.left-nav-list {
	margin-top: 0rem;
	margin-bottom: 1.5rem;
	padding-left: 0px;
	box-shadow: none;
}

.left-nav-column {
	display: block;
	padding-right: 0px;
	padding-left: 0px;
}

.left-nav-nested-list-item {
	margin-bottom: 0px;
	box-shadow: none;
}

.main-content-column {
	padding-right: 16px;
	padding-left: 0px;
}

.left-nav-nested-list {
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	border-top: 2px none #498fa3;
}

.bold-text {
	font-family: Montserrat, sans-serif;
}

.secondary {
	display: inline-block;
	margin-top: 0rem;
	margin-right: 1rem;
	padding: 0.65rem 1rem;
	border-radius: 5px;
	background-color: transparent;
	opacity: 1;
	transition: all 300ms ease, box-shadow 300ms ease, transform 300ms ease, background-color 300ms ease;
	color: #001e45;
	font-size: 0.9rem;
	line-height: 0.95rem;
	font-weight: 600;
	letter-spacing: 0px;
	text-decoration: none;
	text-transform: none;
}

.secondary:hover {
	background-color: rgba(211, 214, 216, 0.5);
	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.27);
}

.secondary-button {
	display: flex;
	padding-left: 0px;
	align-items: center;
	font-family: 'Open Sans', sans-serif;
	font-weight: 800;
}

.heading-19 {
	font-family: Montserrat, sans-serif;
	font-weight: 700;
	margin-top: -1px;
}

.sidebar-section-title-2 {
	margin-top: 0em;
	margin-bottom: 0.75em;
	font-family: Montserrat, sans-serif;
	font-weight: 400;
}

.right-sidebar-column {
	padding-left: 0rem;
}

.right-sidebar-column.right-sidebar {
	padding-top: 16px;
	padding-right: 0px;
	background-color: rgba(211, 214, 216, 0.3);
}

.right-sidebar-column.right-sidebar {
	padding-right: 10px;
	padding-left: 10px;
}

.inside-content-beckground-2 {
	position: relative;
	display: block;
	max-width: 1300px;
	margin-right: auto;
	margin-left: auto;
	padding: 3rem 15px 4rem;
	border-top: 1px solid #000;
	background-color: #fff;
	min-height: 500px;
}

.text-block-22 {
	font-family: Montserrat, sans-serif;
	color: #162b6d;
	font-size: 0.9rem;
	font-weight: 600;
	text-transform: capitalize;
}

.sidebar-list {
	margin-top: 0.5rem;
	margin-bottom: 0.75rem;
	padding-left: 0em;
}

.paragraph-16 {
	font-family: Montserrat, sans-serif;
}

.block-quote-3 {
	margin-top: 10px;
	margin-bottom: 20px;
	border-left-color: #941114;
	background-color: transparent;
	font-family: Montserrat, sans-serif;
	color: #5b5b5b;
	font-size: 1.3rem;
	line-height: 1.75rem;
	font-weight: 500;
}

.link-7 {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	font-family: Montserrat, sans-serif;
	color: #162b6d;
	font-weight: 400;
	text-decoration: none;
}

.link-7:hover {
	border-bottom-style: none;
	border-bottom-color: #549fc4;
}

.link-7.w--current {
	color: #28313b;
	font-weight: 700;
}

.sidebar-section-wrap {
	position: relative;
	margin-bottom: 2.25rem;
	margin-left: 0.5rem;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.sidebar-list-item {
	margin-bottom: 0.75rem;
	padding-left: 0px;
	font-family: Montserrat, sans-serif;
	color: #162b6d;
	text-decoration: none;
}

.left-nav-list-link-2 {
	display: block;
	padding: 0.75rem 1.5rem 0.75rem 1rem;
	border-bottom-style: none;
	background-image: none;
	background-position: 0px 0px;
	background-size: auto;
	background-repeat: repeat;
	transition: all 350ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
	font-family: Montserrat, sans-serif;
	color: #941114;
	font-weight: 500;
	text-decoration: none;
	text-transform: capitalize;
}

.left-nav-list-link-2:hover {
	background-color: rgba(211, 214, 216, 0.3);
	background-image: url('/20181016082423/assets/images/blue-arrow-icon-01_1.svg');
	background-position: 0% 50%;
	background-size: 12px;
	background-repeat: no-repeat;
	transform: translate(5px, 0px);
	color: #162b6d;
	text-decoration: none;
}

.left-nav-list-link-2.w--current {
	background-color: transparent;
	background-image: url('/20181016082423/assets/images/blue-arrow-icon-01_1.svg');
	background-position: 0% 50%;
	background-size: 12px;
	background-repeat: no-repeat;
	color: #162b6d;
}

.main-content-column-2 {
	padding-right: 0px;
	padding-left: 0px;
}

.body-2 {
	font-family: Montserrat, sans-serif;
}

.body-3 {
	font-family: Montserrat, sans-serif;
}

.body-4 {
	font-family: Montserrat, sans-serif;
}

.body-5 {
	font-family: Montserrat, sans-serif;
}

@media screen and (max-width: 1135px) and (min-width: 992px) {
	.contact-link,
	.news-link {
		margin-left: 0.7rem;
	}

	.nav-top-header-wrapper {
		padding-right: 0px;
		margin-right: -10px;
	}
}

@media screen and (max-width: 991px) {
	.menu-icon {
		position: static;
		display: flex;
		margin-right: auto;
		margin-left: 16px;
		padding-right: 50px;
		align-items: flex-end;
		font-size: 2.25rem;
		margin-top: 15px;
	}

	.search-input {
		padding-left: 0rem;
	}

	.contact-link {
		margin-left: 2rem;
	}

	.navbar {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: stretch;
	}

	.mobile-nav-link {
		display: flex;
		width: 100%;
		padding-top: 20px;
		padding-bottom: 20px;
		padding-left: 1.5rem;
		justify-content: center;
		border-bottom: 1px solid hsla(0, 0%, 85.9%, 0.15);
		color: #fff;
		font-size: 0.95rem;
		line-height: 1.15rem;
		font-weight: 500;
		letter-spacing: 0em;
		text-transform: none;
	}

	.mobile-nav-link:hover {
		padding-right: 0px;
		padding-left: 1.5rem;
		background-color: #001e45;
		color: #fff;
	}

	.two-part-button-wrap {
		display: flex;
		width: 100%;
		height: 100%;
		max-width: none;
		margin-right: 0px;
		margin-left: 0px;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		grid-auto-columns: 1fr;
		grid-column-gap: 287px;
		grid-row-gap: 16px;
		-ms-grid-columns: 1fr 1fr 1fr;
		grid-template-columns: 1fr 1fr 1fr;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
		border-bottom: 1px solid hsla(0, 0%, 85.9%, 0.15);
	}

	.two-part-button-wrap:hover {
		background-color: #001e45;
	}

	.online-banking-login-button {
		padding-left: 0.75rem;
	}

	.logo {
		display: block;
		width: 100px;
		margin-top: 0px;
	}

	.search {
		display: none;
		margin-right: 0rem;
		margin-left: 0px;
		padding-right: 0rem;
		padding-left: 0rem;
		order: 0;
	}

	.search-bar-wrap {
		top: 102px;
		right: 3rem;
		z-index: 50;
		display: none;
		width: 50%;
		height: 55px;
	}

	.two-part-drop-list-back-arrow {
		display: inline-block;
		min-width: 0.625em;
		margin-right: 0.3125em;
		background-image: url('/20181016082423/assets/images/icon_arrow2_left_wht_50.svg');
		background-position: 0px 50%;
		background-size: 11px 12px;
		background-repeat: no-repeat;
		opacity: 0.6;
		color: transparent;
		text-align: center;
	}

	.news-link {
		margin-left: 2rem;
	}

	.nav-menu {
		top: auto;
		display: block;
		margin-top: 80px;
		background-color: #941114;
	}

	.search-icon {
		width: 40px;
		height: 40px;
		min-height: auto;
		margin-top: auto;
		margin-right: 0px;
		margin-left: 16px;
		padding-top: 0rem;
		padding-bottom: 0rem;
		background-size: 17px;
	}

	.search-icon:hover {
		background-size: 17px;
	}

	.menu-button {
		display: flex;
		margin-left: 0px;
		padding: 0px;
		color: #1c3d66;
	}

	.menu-button.w--open {
		background-color: transparent;
		color: #2d2d2d;
		cursor: pointer;
	}

	.main-navbar-wrapper {
		top: 0px;
		right: auto;
		z-index: 1000;
		width: auto;
		height: 0%;
		padding-right: 0rem;
		padding-left: 0rem;
		flex-direction: row;
		justify-content: flex-end;
		flex-wrap: wrap;
		align-items: flex-end;
		align-content: flex-end;
	}

	.two-part-button-toggle {
		display: block;
		width: 50px;
		margin-bottom: 0px;
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
		border-left: 1px solid hsla(0, 0%, 85.9%, 0.15);
		background-color: transparent;
		background-image: url('/20181016082423/assets/images/white-arrow-icon.svg');
		background-position: 50% 50%;
		background-size: 1em 1em;
		background-repeat: no-repeat;
		color: transparent;
		font-size: 11px;
		line-height: 20px;
		text-align: center;
		cursor: pointer;
	}

	.two-part-button-toggle:hover {
		background-color: transparent;
	}

	.online-banking-icon {
		margin-right: 0px;
	}

	.link-text {
		opacity: 1;
	}

	.mobile-nav-link-dropdown {
		z-index: 140;
		display: block;
		width: 100%;
		margin-right: 0px;
		margin-bottom: 1px;
		padding: 1em 1rem 1em 1.5rem;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		transition: box-shadow 200ms ease, padding 200ms ease, background-color 200ms ease, color 200ms ease, font-size 200ms ease;
		color: #fff;
		font-size: 1rem;
		line-height: 1.15rem;
		font-weight: 500;
		text-align: left;
		text-decoration: none;
		text-transform: capitalize;
	}

	.mobile-nav-link-dropdown:hover {
		padding-right: 0.75rem;
		padding-left: 1.75rem;
		background-color: rgba(250, 251, 252, 0.05);
		box-shadow: none;
		color: #3eedb6;
	}

	.mobile-nav-link-dropdown.w--current {
		background-color: transparent;
		box-shadow: inset 5px 0 0 0 #3128b1, 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		color: #fff;
	}

	.mobile-nav-link-dropdown.w--current:hover {
		background-color: transparent;
		color: #fff;
	}

	.mobile-nav-link-dropdown.two-part-mobile {
		display: flex;
		margin-right: 0px;
		margin-left: 0px;
		padding: 20px 2rem 20px 1.5rem;
		clear: none;
		justify-content: center;
		flex: 0 auto;
		border-bottom: 1px solid rgba(51, 51, 51, 0.05);
		box-shadow: none;
		transition-property: none;
		color: #fff;
		font-size: 0.95rem;
		line-height: 1.15rem;
		font-weight: 500;
		letter-spacing: 0px;
		text-decoration: none;
		text-transform: none;
	}

	.mobile-nav-link-dropdown.two-part-mobile:hover {
		padding-left: 1.5rem;
		flex-grow: 0;
		border-bottom-color: rgba(51, 51, 51, 0.03);
		background-color: #001e45;
		box-shadow: none;
		color: #fff;
		font-style: normal;
	}

	.mobile-nav-link-dropdown.mobile-list-back {
		padding-left: 0.5625rem;
		background-color: rgba(250, 251, 252, 0.05);
		box-shadow: none;
		color: #3eedb6;
		font-size: 1rem;
		line-height: 1.15rem;
		letter-spacing: 0em;
		text-transform: capitalize;
		cursor: pointer;
	}

	.mobile-nav-link-dropdown.mobile-list-back:hover {
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		color: #fff;
	}

	.two-part-button-list {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 150;
		display: block;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		background-color: #1d1e50;
	}

	.nav-link-wrapper {
		display: none;
		padding-left: 0.5rem;
		align-items: flex-start;
		border-bottom: 1px solid hsla(0, 0%, 85.9%, 0.15);
	}

	.nav-link-wrapper:hover {
		background-color: #212121;
	}

	.nav-top-header-wrapper {
		display: none;
		padding-right: 0%;
		padding-left: 0rem;
		justify-content: flex-end;
	}

	.search-input-exit {
		margin-right: 0rem;
	}

	.slider-h2-heading {
		font-size: 2rem;
		line-height: 2.4rem;
	}

	.slider-contents-wrapper {
		top: 10%;
		display: flex;
		max-width: 500px;
		padding: 1.5rem 2rem 1.5rem 3.5rem;
		flex-direction: column;
		align-items: flex-start;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
	}

	.primary-button {
		display: inline-block;
		height: auto;
		margin-top: 0.25rem;
		margin-right: 0px;
		justify-content: center;
		align-items: center;
		align-self: flex-start;
	}

	.primary-button.inside-pages {
		margin-right: 16px;
		margin-left: 0px;
	}

	.slider-text {
		font-size: 0.95rem;
		line-height: 1.2rem;
	}

	.slide-2 {
		background-image: url('/20181016082423/assets/images/AdobeStock_284962887_Preview.jpeg');
		background-position: 50% 50%;
	}

	.slideshow {
		top: 0px;
		height: 65vh;
		min-height: auto;
		margin-top: 135px;
	}

	.icon-5 {
		font-size: 2.25rem;
	}

	.slider-container {
		max-width: none;
		padding-right: 6rem;
		padding-left: 6rem;
		justify-content: flex-start;
	}

	.scroll-arrow-holder {
		display: none;
	}

	.scroll-down-arrow {
		margin-top: 175px;
		margin-right: 5px;
		font-size: 28px;
	}

	.home-content-section {
		left: 0%;
		width: 100%;
		margin-left: 0px;
		padding: 1rem;
		flex-direction: column;
	}

	.home-content-section.quick-links-section {
		top: 0px;
		margin-top: 0px;
		padding-right: 3rem;
		padding-bottom: 0rem;
		padding-left: 3rem;
	}

	.home-content-section.newsletter {
		background-position: 100% 10%;
		background-size: 200px;
	}

	.home-content-section.newsletter {
		top: 0px;
		background-position: 100% 0%;
	}

	.h3-headings {
		margin-top: 1rem;
		margin-bottom: 0rem;
		line-height: 2.25rem;
		text-align: center;
		text-indent: 0px;
	}

	.h3-headings.icon-headings {
		margin-top: 0.25rem;
		line-height: 1.5rem;
	}

	.icon-wrap {
		width: 4rem;
		height: 4rem;
		margin-bottom: 0.5rem;
	}

	.icon-link-wrapper {
		width: 50%;
		margin-bottom: 4rem;
	}

	.icon-wrapper {
		width: 100%;
		flex-wrap: wrap;
	}

	.hero-slide-overlay {
		left: -94.5vw;
	}

	.hero-slide-overlay-copy {
		background-position: 0% 50%;
		background-size: auto 50%;
	}

	.slider-copy {
		display: flex;
		width: 50vw;
		flex-direction: column;
	}

	.heading.white {
		display: flex;
		width: 95%;
		justify-content: center;
		text-align: center;
	}

	.heading-wrapper {
		align-items: center;
	}

	.text-block {
		width: 500px;
		text-align: left;
	}

	.text-block.centered {
		text-align: center;
	}

	.text-block.centered {
		text-align: left;
	}

	.text-block.centered.white {
		width: auto;
	}

	.primary-button-2 {
		display: flex;
		height: auto;
		margin-top: 1.5rem;
		justify-content: flex-start;
		align-items: center;
	}

	.newsletter-text-field {
		height: auto;
		padding-top: 0.65rem;
		padding-bottom: 0.65rem;
	}

	.horiz-heading-underline {
		margin-bottom: 1.25rem;
	}

	.horiz-heading-underline.white {
		width: 95%;
	}

	.parallax-wrapper {
		top: 0px;
	}

	.block-wrapper.parallax.block-padding {
		background-image: linear-gradient(180deg, rgba(32, 60, 128, 0.5), rgba(32, 60, 128, 0.5)), url('/20181016082423/assets/images/AdobeStock_296159541.jpeg');
		background-position: 0px 0px, 0% 50%;
	}

	.parallax-content-wrapper {
		margin-top: 2rem;
	}

	.cta-block {
		width: 75%;
		max-width: none;
		margin-right: auto;
		margin-left: auto;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		-webkit-backdrop-filter: blur(5px);
		backdrop-filter: blur(5px);
	}

	.div-block-122.red {
		width: 150px;
		height: 10px;
		margin-bottom: 20px;
	}

	.paragraph-2 {
		text-align: justify;
	}

	.footer-content-wrapper {
		display: -ms-grid;
		display: grid;
		width: 100%;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		grid-auto-columns: 1fr;
		grid-column-gap: 16px;
		grid-row-gap: 16px;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
	}

	.image-links {
		height: auto;
		justify-content: center;
		flex: 0 100%;
	}

	.footer-block {
		top: 0px;
	}

	.links-container {
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: center;
		flex: 0 100%;
		grid-row-gap: 0rem;
		text-align: center;
	}

	.footer-links {
		height: auto;
		flex-direction: row;
		align-items: flex-start;
		grid-column-gap: 2rem;
		grid-row-gap: 0.25rem;
	}

	.social-link {
		transition-property: all;
		transition-duration: 300ms;
	}

	.social-link:hover {
		border-radius: 0%;
	}

	.footer-grip-link.white-logo {
		width: 300px;
	}

	.wr-copyright-content {
		padding-bottom: 0.25rem;
		padding-left: 0vw;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex: 0 100%;
	}

	.footer-text {
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	.footer-link.footer-link-item {
		display: inline-block;
	}

	.housing-logos {
		width: 65%;
	}

	.div-block-123 {
		width: 100%;
	}

	.cb-static.footer-cb-left {
		width: 25%;
		margin-right: 2rem;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.cb-static.footer-cb-left.top-cb-static {
		width: 100%;
		margin-right: 0rem;
		padding-top: 0.8rem;
		padding-bottom: 0.8rem;
		align-items: center;
	}

	.cb-heading.footer-heading {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.cb-heading.footer-heading-copy {
		font-size: 1rem;
		line-height: 1.5rem;
	}

	.footer-nav-list {
		margin-bottom: 0rem;
		padding-left: 0px;
	}

	.footer-nav-item {
		margin-bottom: 0.5rem;
		text-align: center;
	}

	.div-block-125 {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		align-self: center;
	}

	.card-wrapper.block-padding {
		top: 0px;
	}

	.card-block-wrapper {
		display: -ms-grid;
		display: grid;
		justify-items: center;
		grid-auto-columns: 1fr;
		grid-column-gap: 2rem;
		grid-row-gap: 16px;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
	}

	.card {
		display: flex;
		margin-right: auto;
		margin-left: auto;
		grid-row-gap: 0rem;
	}

	.card-title:hover {
		background-color: rgba(143, 180, 68, 0.5);
	}

	.logo-mobile {
		left: 2.5rem;
		display: none;
		width: auto;
		height: auto;
	}

	.online-banking-login-mobile-button {
		position: static;
		left: 25%;
		right: 0%;
		display: none;
		margin-right: 0px;
		margin-left: 0px;
		padding-left: 0.75rem;
		justify-content: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.online-banking-login-mobile-button-copy {
		position: relative;
		right: auto;
		display: flex;
		margin-left: 0px;
		padding-top: 1rem;
		padding-bottom: 1rem;
		padding-left: 0.75rem;
		justify-content: center;
	}

	.link-text-copy {
		opacity: 1;
	}

	.div-block-127 {
		position: static;
		justify-content: center;
	}

	.kutup-dropdown-arrow {
		margin-right: 30px;
	}

	.kutup-dropdown-toggle {
		width: 100%;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.kutup-dropdown-toggle-copy {
		width: 100%;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.kutup-dropdown-arrow-copy {
		margin-right: 30px;
	}

	.kutup-dropdown-toggle-copy {
		width: 100%;
		padding-top: 10px;
		padding-bottom: 10px;
	}

	.nav_megamenu {
		position: relative;
		overflow: hidden;
	}

	.nav_megamenu.w--open {
		position: relative;
		left: 0rem;
		top: 0rem;
		right: 0rem;
		min-width: auto;
		margin-bottom: 1.5rem;
		padding: 0rem 0px 0px;
		align-items: flex-start;
		border-style: none;
	}

	.rl-dropdown-icon {
		left: auto;
		top: auto;
		right: 0%;
		bottom: auto;
		margin-top: 0rem;
		margin-right: 0px;
		margin-bottom: 0rem;
	}

	.rl_navbar5_dropdown-toggle {
		display: none;
		padding-top: 0.75rem;
		padding-bottom: 0.75rem;
		padding-left: 0rem;
		align-items: center;
		font-size: 1.125rem;
	}

	.nav-megamenu {
		position: relative;
		width: 100%;
	}

	.nav-link-wrapper-copy {
		display: none;
		padding-left: 0.5rem;
		align-items: flex-start;
		border-bottom: 1px solid hsla(0, 0%, 85.9%, 0.15);
	}

	.nav-link-wrapper-copy:hover {
		background-color: #212121;
	}

	.kutup-dropdown-arrow-copy {
		margin-right: 30px;
	}

	.menu-button-copy {
		position: static;
		display: flex;
		margin-left: 0px;
		padding: 0px;
		align-items: flex-end;
		color: #1c3d66;
		cursor: pointer;
	}

	.menu-button-copy.w--open {
		background-color: transparent;
		color: #2d2d2d;
		cursor: pointer;
	}

	.nav-menu-mob {
		position: absolute;
		left: 0%;
		top: 100%;
		right: 0%;
		bottom: auto;
		display: flex;
		margin-top: 135px;
		justify-content: center;
		align-items: center;
		background-color: #941114;
	}

	.login-link-copy {
		-ms-grid-row-align: center;
		align-self: center;
	}

	.login-link {
		-ms-grid-row-align: center;
		align-self: center;
	}

	.div-block-128 {
		margin-right: 40px;
	}

	.div-block-129 {
		-ms-grid-row-align: center;
		align-self: center;
	}

	.div-block-130 {
		display: flex;
	}

	.card-2 {
		display: flex;
		grid-row-gap: 0rem;
		width: 100%;
	}

	.card-image-2 {
		width: 100%;
		height: 250px;
		max-width: 250px;
	}

	.card-title-2 {
		font-size: 1.3rem;
	}

	.call-box {
		width: 100%;
		justify-content: center;
		-ms-grid-row-align: stretch;
		align-self: stretch;
		text-align: left;
	}

	.heading-wrapper-2 {
		align-items: center;
	}

	.div-block-131 {
		padding-right: 60px;
	}

	.newsletter-cb-wrapper-2 {
		justify-content: space-between;
		align-items: stretch;
	}

	.div-block-132 {
		width: auto;
		padding-right: 16px;
		padding-left: 16px;
		justify-content: center;
		align-items: center;
	}

	.newsletter-text-field-2 {
		height: auto;
		padding-top: 0.65rem;
		padding-bottom: 0.65rem;
	}

	.div-block-126-copy {
		width: auto;
		padding-right: 16px;
		padding-left: 16px;
		justify-content: center;
		align-items: flex-start;
	}

	.newsletter-content-section-2 {
		flex-direction: column;
		align-items: center;
		text-align: left;
	}

	.div-block-133 {
		-ms-grid-row-align: center;
		align-self: center;
	}

	.paragraph-4 {
		text-align: center;
	}

	.div-block-134 {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}

	.header {
		left: -1%;
		right: -1%;
		height: 85px;
		-ms-grid-columns: 0.5fr 1fr 0.5fr;
		grid-template-columns: 0.5fr 1fr 0.5fr;
		-ms-grid-rows: auto;
		grid-template-rows: auto;
		border-top: 10px none #001e45;
		border-bottom: 2px solid #941114;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
	}

	.main-content-section.right {
		padding-right: 0px;
	}

	.main-content-wrapper {
		width: 100%;
	}

	.section-wrap {
		margin-top: 0px;
		padding-top: 0px;
		margin-top: 167px;
	}

	.breadcrumb-list {
		margin-top: -7px;
	}

	.div-block-140 {
		width: 65%;
	}

	.inside-content-beckground {
		max-width: 100%;
		margin-top: 0px;
		padding: 1.5rem 40px 2rem;
	}

	.inside-page-background-image {
		margin-right: 0px;
		margin-left: 0px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.inside-page-header-content-wrap-2 {
		margin-bottom: 1rem;
	}

	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0px;
	}

	.content-container {
		width: 100%;
		padding-left: 0rem;
	}

	.content-container.threequarterwidth {
		width: 100%;
		padding-left: 0rem;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.main-content-column {
		padding-right: 0px;
		padding-left: 0px;
	}

	.secondary {
		display: inline-block;
		height: auto;
		margin-top: 0.25rem;
		margin-right: 0px;
		justify-content: center;
		align-items: center;
		align-self: flex-start;
	}

	.right-sidebar-column {
		margin-top: 1.5rem;
		padding-top: 1.5rem;
		border-top: 3px solid #48bdd7;
	}

	.right-sidebar-column.right-sidebar {
		border-top-style: none;
	}

	.right-sidebar-column.right-sidebar {
		border-top-style: none;
	}

	.inside-content-beckground-2 {
		max-width: 100%;
		padding: 1.5rem 40px 2rem;
	}

	.sidebar-section-wrap {
		margin-left: 0em;
	}

	.main-content-column-2 {
		padding-right: 0px;
		padding-left: 0px;
	}
}

@media screen and (max-width: 767px) {
	.section-wrap {
		margin-top: 166px;
	}

	.div-block-121 {
		width: 100%;
		justify-content: flex-end;
	}

	.logo-image {
		display: block;
	}

	.menu-icon {
		margin-top: 8px;
		margin-right: 0px;
		margin-left: 0px;
		padding-right: 0px;
	}

	.search-input {
		width: 450px;
	}

	.contact-link {
		margin-left: 1.25rem;
	}

	.navbar {
		justify-content: center;
		align-items: center;
	}

	.online-banking-login-button {
		padding: 0.5rem;
		flex: 0 0 auto;
		background-image: none;
		font-size: 0.8rem;
	}

	.logo {
		width: 100px;
		height: auto;
		padding-left: 0px;
	}

	.search {
		display: none;
		margin-right: 16px;
		margin-left: auto;
		flex-direction: row;
		flex-wrap: nowrap;
		-ms-grid-row-align: center;
		align-self: center;
		font-size: 16px;
	}

	.search-bar-wrap {
		top: 102px;
		right: auto;
		z-index: 5;
		display: none;
		width: 80%;
		height: 45px;
		margin-right: auto;
		margin-left: auto;
		transform: translate(0px, -80px);
	}

	.news-link {
		margin-left: 1.25rem;
	}

	.nav-menu {
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		display: none;
		margin-top: 80px;
	}

	.search-icon {
		width: 40px;
		height: 40px;
		background-size: 17px;
	}

	.menu-button {
		margin-left: 0px;
	}

	.main-navbar-wrapper {
		top: 0px;
		z-index: 1000;
		margin-right: 0px;
		margin-left: 0px;
		padding-right: 0rem;
		padding-left: 0rem;
		justify-content: center;
		flex-wrap: nowrap;
		align-items: flex-start;
	}

	.two-part-button-toggle {
		width: 40%;
	}

	.online-banking-icon {
		margin-right: 0px;
	}

	.link-text {
		font-size: 0.8rem;
		text-align: left;
	}

	.mobile-nav-link-dropdown.two-part-mobile {
		display: flex;
		width: 85%;
		justify-content: flex-end;
	}

	.mobile-nav-link-dropdown.two-part-mobile:hover {
		width: 85%;
	}

	.nav-top-header-wrapper {
		padding: 0.5rem 1rem;
		flex-direction: row;
		justify-content: space-between;
	}

	.search-input-exit {
		margin-right: 0rem;
	}

	.slide-nav {
		font-size: 0.75rem;
	}

	.slider-contents-wrapper {
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		width: auto;
		max-width: 100%;
		padding-right: 0rem;
		padding-left: 0rem;
		align-items: center;
		background-image: none;
		background-size: auto;
		background-repeat: repeat;
	}

	.primary-button {
		justify-content: flex-start;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.slide-2 {
		background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 20%, hsla(0, 0%, 100%, 0.13) 75%), url('/20181016082423/assets/images/AdobeStock_284962887_Preview.jpeg');
		background-position: 0px 0px, 50% 50%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
	}

	.slideshow {
		height: 60vh;
	}

	.icon-5 {
		margin-bottom: 0px;
		font-size: 2rem;
	}

	.slider-container {
		top: 0px;
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.scroll-down-arrow {
		margin-top: 125px;
		margin-right: 5px;
	}

	.home-content-section {
		width: 100%;
		height: auto;
		padding: 0rem;
	}

	.home-content-section.quick-links-section {
		padding-top: 2rem;
		padding-bottom: 2rem;
	}

	.home-content-section.newsletter {
		padding-right: 3rem;
		padding-left: 3rem;
		background-position: 100% 5%;
		background-size: 130px;
	}

	.home-content-section.newsletter {
		padding-right: 3rem;
		padding-left: 3rem;
	}

	.h3-headings {
		margin-top: 0px;
		margin-bottom: 0.5rem;
	}

	.h3-headings.icon-headings {
		margin-bottom: 0.5rem;
	}

	.icon-wrap {
		width: 4rem;
		height: 4.5rem;
	}

	.icon-link-wrapper {
		width: 50%;
		margin-bottom: 2.5rem;
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.icon-wrapper {
		flex-direction: row;
		justify-content: center;
	}

	.hero-slide-overlay {
		left: 0%;
		top: auto;
		right: 0%;
		bottom: 0%;
		display: none;
		height: 50%;
		transform: none;
	}

	.hero-slide-overlay-copy {
		display: none;
	}

	.slider-copy {
		width: 80vw;
		padding-right: 20px;
		padding-left: 20px;
		flex-direction: column;
		align-items: flex-start;
	}

	.slide-2-copy {
		background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 20%, hsla(0, 0%, 100%, 0.13) 75%), url('/20181016082423/assets/images/slideshow-image-2.jpg');
		background-position: 0px 0px, 50% 50%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
	}

	.newsletter-form {
		justify-content: center;
		flex-wrap: wrap;
	}

	.heading {
		font-size: 2.5rem;
	}

	.heading.white {
		width: 90%;
	}

	.text-block {
		width: auto;
		max-width: none;
		margin-bottom: 1.5rem;
	}

	.text-block.centered {
		margin-bottom: 1.5rem;
	}

	.text-block.centered {
		margin-bottom: 0.5rem;
	}

	.newsletter-form-cb {
		min-width: 265px;
		padding-bottom: 0px;
		align-items: center;
	}

	.newsletter-text-field {
		margin-right: 1rem;
		margin-bottom: 0rem;
	}

	.horiz-heading-underline {
		margin-bottom: 1rem;
	}

	.horiz-heading-underline.white {
		width: 90%;
	}

	.block-wrapper.parallax.block-padding {
		background-image: linear-gradient(180deg, null, null), url('/20181016082423/assets/images/AdobeStock_296159541.jpeg');
		background-position: 0px 0px, 50% 50%;
	}

	.parallax-content-wrapper {
		flex-direction: column;
		grid-row-gap: 2rem;
	}

	.cta-block {
		width: 90%;
		margin-right: 16px;
		margin-left: 16px;
		justify-content: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.footer-links {
		flex-direction: column;
		align-items: center;
	}

	.footer-grip-link.white-logo {
		width: 300px;
	}

	.copyright {
		margin-right: 1rem;
		margin-left: 1rem;
	}

	.wr-copyright-content {
		margin-right: 0rem;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		text-align: left;
	}

	.footer-text {
		text-align: left;
	}

	.housing-logos {
		display: flex;
		width: 100%;
		justify-content: center;
		flex: 0 auto;
	}

	.ncua-logo {
		display: inline-block;
	}

	.ehl-logo {
		display: inline-block;
	}

	.cb-static {
		width: 33.33%;
	}

	.cb-static.footer-cb-left {
		width: 40%;
		margin-right: 0rem;
	}

	.cb-static.footer-cb-left.top-cb-static {
		margin-bottom: 0rem;
	}

	.footer-nav-list {
		margin-left: 0rem;
	}

	.footer-nav-item {
		margin-top: 0rem;
		margin-bottom: 0rem;
	}

	.div-block-125 {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		align-self: auto;
	}

	.card-block-wrapper {
		display: -ms-grid;
		display: grid;
		justify-content: space-between;
		flex-wrap: wrap;
		grid-auto-columns: 1fr;
		grid-column-gap: 1rem;
		grid-row-gap: 1rem;
		-ms-grid-columns: 1fr 1fr;
		grid-template-columns: 1fr 1fr;
		-ms-grid-rows: auto auto;
		grid-template-rows: auto auto;
	}

	.logo-mobile {
		width: auto;
		height: auto;
		max-width: none;
		margin-right: auto;
		margin-left: auto;
		padding-left: 0px;
	}

	.online-banking-login-mobile-button {
		left: 20%;
		display: none;
		padding: 0.5rem;
		flex: 0 0 auto;
		background-image: none;
		font-size: 0.8rem;
	}

	.online-banking-login-mobile-button-copy {
		left: 0%;
		display: flex;
		margin-right: 0px;
		margin-left: 0px;
		padding: 0.5rem;
		justify-content: center;
		align-self: center;
		flex: 0 auto;
		background-image: none;
		font-size: 0.8rem;
	}

	.link-text-copy {
		font-size: 0.8rem;
		text-align: left;
	}

	.menu-button-copy {
		margin-left: 0px;
	}

	.nav-menu-mob {
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		margin-top: 0px;
	}

	.form-block {
		margin-bottom: 0px;
	}

	.login-link-copy {
		display: flex;
	}

	.login-link {
		display: flex;
	}

	.div-block-128 {
		margin-right: 20px;
	}

	.div-block-129 {
		display: flex;
		height: 100%;
		padding-top: 20px;
		flex-direction: row;
		justify-content: center;
		align-items: flex-start;
	}

	.div-block-130 {
		display: flex;
		flex-direction: row;
		align-items: center;
		align-self: flex-end;
	}

	._4cards-wrapper-2 {
		margin-right: 20px;
		margin-left: 20px;
		grid-column-gap: 33px;
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
		-ms-grid-rows: auto auto auto auto;
		grid-template-rows: auto auto auto auto;
	}

	.card-title-2 {
		font-size: 1rem;
	}

	.call-box {
		width: 95%;
	}

	.newsletter-form-2 {
		justify-content: center;
		flex-wrap: wrap;
	}

	.div-block-131 {
		padding-right: 0px;
	}

	.newsletter-text-field-2 {
		margin-right: 1rem;
		margin-bottom: 0rem;
		width: 249px;
	}

	.header {
		align-items: center;
		-ms-grid-columns: 1fr 0.5fr 1fr;
		grid-template-columns: 1fr 0.5fr 1fr;
	}

	.h4-header-link {
		font-size: 1.25rem;
	}

	.ordered-list-2 {
		padding-left: 20px;
	}

	.inside-content-beckground {
		padding-right: 30px;
		padding-bottom: 1.5rem;
		padding-left: 30px;
	}

	.h3-header-link {
		font-size: 1.5rem;
	}

	.inside-page-background-image {
		min-height: 80px;
	}

	.h2-header-link {
		font-size: 1.75rem;
	}

	.intro-paragraph-2 {
		font-size: 1.125rem;
	}

	.block-quote-2 {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.unordered-list {
		padding-left: 20px;
	}

	.h5-header-link {
		font-size: 1.125rem;
	}

	.inside-page-header-content-wrap-2 {
		padding-right: 0px;
		padding-left: 0px;
	}

	.content-container.threequarterwidth {
		padding-right: 0rem;
	}

	.secondary {
		justify-content: flex-start;
		-ms-grid-row-align: center;
		align-self: center;
	}

	.right-sidebar-column {
		margin-top: 1.25rem;
		padding-top: 1.25rem;
	}

	.inside-content-beckground-2 {
		padding-right: 30px;
		padding-bottom: 1.5rem;
		padding-left: 30px;
	}

	.block-quote-3 {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}

	.sidebar-section-wrap {
		margin-left: 0rem;
	}

	.wrapper {
		width: 100%;
	}
}

@media screen and (max-width: 479px) {
	.breadcrumb-list {
		margin-left: 0px;
		margin-right: 0px;
	}

	.div-block-121 {
		display: none;
		width: 100%;
		padding: 0.5rem 1rem;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: stretch;
	}

	.menu-icon {
		display: block;
		font-size: 2.25rem;
	}

	.about-link {
		margin-right: 0rem;
		margin-bottom: 0rem;
		margin-left: 0vw;
		padding-top: 0rem;
		padding-bottom: 0rem;
	}

	.search-input {
		width: 250px;
		margin-right: 1.25rem;
		font-size: 0.8rem;
	}

	.contact-link {
		margin-right: 0rem;
		margin-left: 1rem;
	}

	.mobile-nav-link:hover {
		background-color: #001e45;
	}

	.two-part-button-wrap:hover {
		background-color: #001e45;
	}

	.online-banking-login-button {
		position: relative;
		z-index: 99;
		display: none;
		width: 100%;
		margin-top: 0rem;
		margin-left: 0px;
		padding: 0.75rem 3rem;
		justify-content: center;
		align-items: center;
		border-radius: 0px;
		background-image: linear-gradient(180deg, transparent, transparent);
		font-size: 0.9rem;
	}

	.logo {
		max-width: 175px;
		padding-left: 0px;
	}

	.search {
		display: none;
		margin-right: 0.5rem;
	}

	.search-bar-wrap {
		top: 95px;
		right: auto;
		z-index: 1;
		display: none;
		width: 85%;
		height: 55px;
		margin-right: auto;
		padding-right: 1rem;
		padding-left: 1rem;
		opacity: 1;
	}

	.news-link {
		margin-right: 1rem;
		margin-bottom: 0rem;
		margin-left: 1rem;
		padding-left: 0rem;
	}

	.nav-menu {
		position: absolute;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		display: none;
		width: 0%;
		margin-top: 0px;
		margin-right: -252px;
		justify-content: space-between;
	}

	.search-icon {
		padding-right: 0.35rem;
		padding-left: 1.5rem;
	}

	.main-navbar-wrapper {
		top: 0px;
		z-index: 1000;
		justify-content: center;
		align-items: center;
		align-content: center;
	}

	.online-banking-icon {
		display: flex;
		margin-right: 0px;
	}

	.link-text {
		opacity: 1;
		font-size: 0.725rem;
		line-height: 1rem;
		text-align: left;
	}

	.link-text.banking-login-button {
		font-size: 0.9rem;
	}

	.mobile-nav-link-dropdown.two-part-mobile:hover {
		width: 85%;
		background-color: #001e45;
	}

	.nav-top-header-wrapper {
		position: relative;
		padding: 0rem;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: wrap;
		align-items: center;
	}

	.search-input-exit {
		height: 25px;
		margin-right: 0rem;
	}

	.slider-h2-heading {
		font-size: 1.5rem;
		line-height: 1.85rem;
		text-align: center;
	}

	.slide-nav {
		font-size: 0.7rem;
	}

	.slider-contents-wrapper {
		left: 0px;
		top: 22%;
		right: 0px;
		bottom: 0px;
		display: flex;
		width: auto;
		height: 300px;
		margin-top: 0px;
		padding: 2rem 1.5rem 0rem;
		flex-direction: column;
		align-items: center;
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.primary-button {
		display: flex;
		margin-top: 1rem;
		margin-right: auto;
		margin-left: auto;
		justify-content: center;
		font-size: 0.8rem;
	}

	.left-arrow-icon {
		display: none;
	}

	.slider-text {
		text-align: center;
	}

	.slide-2 {
		background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 20%, hsla(0, 0%, 100%, 0.13) 88%), url('/20181016082423/assets/images/AdobeStock_284962887_Preview.jpeg');
		background-position: 0px 0px, 50% 50%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
	}

	.slideshow {
		height: 50vh;
	}

	.slider-container {
		max-width: 250px;
		justify-content: center;
	}

	.right-arrow-icon {
		display: none;
	}

	.scroll-down-arrow {
		margin-top: 75px;
		margin-right: 0px;
	}

	.home-content-section {
		position: static;
		width: 100%;
		height: auto;
		padding: 0.5rem 0rem;
	}

	.home-content-section.quick-links-section {
		padding: 2rem 2rem 1rem;
	}

	.home-content-section.newsletter {
		padding: 3rem 2rem;
		background-position: 100% 90%;
		background-size: 100px;
	}

	.home-content-section.newsletter {
		padding: 3rem 0.4rem;
		align-items: center;
		background-position: 100% 10%;
		background-size: 250px;
	}

	.h3-headings {
		font-size: 0.9rem;
		line-height: 2.2rem;
	}

	.h3-headings.icon-headings {
		font-size: 1.1rem;
		letter-spacing: 0rem;
	}

	.icon-img {
		width: 3.5rem;
	}

	.icon-wrap {
		width: 3.5rem;
		height: 4rem;
	}

	.icon-link-wrapper {
		width: 100%;
	}

	.icon-wrapper {
		margin-bottom: -1rem;
		flex-direction: column;
		justify-content: flex-end;
	}

	.slider-copy {
		position: relative;
		top: -50px;
		margin-top: auto;
		align-items: center;
	}

	.slide-2-copy {
		background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.3) 20%, hsla(0, 0%, 100%, 0.13) 88%), url('/20181016082423/assets/images/slideshow-image-2.jpg');
		background-position: 0px 0px, 50% 50%;
		background-size: auto, cover;
		background-repeat: repeat, no-repeat;
	}

	.newsletter-form {
		margin-bottom: 0px;
		flex-direction: column;
	}

	.heading {
		font-size: 2.25rem;
		line-height: 2.5rem;
		text-align: center;
	}

	.heading.white {
		text-align: center;
	}

	.heading.white {
		font-size: 1.5rem;
		line-height: 1.8rem;
		text-align: center;
	}

	.heading-wrapper {
		margin-bottom: 0rem;
	}

	.text-block {
		max-width: 350px;
		margin-bottom: 2rem;
		font-size: 0.9rem;
		line-height: 1.25rem;
		text-align: center;
	}

	.text-block.centered {
		margin-bottom: 2rem;
	}

	.text-block.centered {
		margin-bottom: 0.5rem;
	}

	.primary-button-2 {
		margin-top: 1rem;
		margin-right: 0rem;
		font-size: 0.8rem;
	}

	.newsletter-form-cb {
		width: auto;
		min-width: 200px;
		flex-wrap: wrap;
		flex-direction: column;
	}

	.newsletter-text-field {
		margin-right: 0px;
	}

	.horiz-heading-underline.white {
		margin-bottom: 1rem;
	}

	.block-wrapper {
		max-width: none;
	}

	.block-wrapper.parallax.block-padding {
		min-height: 0px;
		padding-right: 0rem;
	}

	.parallax-content-wrapper {
		align-items: center;
	}

	.cta-block {
		padding: 2rem 1rem;
		flex-direction: column;
		align-items: center;
		font-size: 1rem;
	}

	.flourish {
		margin-top: 0px;
		text-align: center;
	}

	.div-block-122.red {
		width: 150px;
		height: 10px;
		margin-bottom: 20px;
		padding-bottom: 0px;
	}

	.paragraph-2 {
		text-align: center;
	}

	.footer-content-wrapper {
		flex-direction: column;
		grid-row-gap: 1rem;
		-ms-grid-columns: 1fr;
		grid-template-columns: 1fr;
	}

	.image-links {
		margin-bottom: 0rem;
	}

	.links-container {
		grid-row-gap: 1rem;
	}

	.footer-links {
		padding-right: 1rem;
		padding-left: 1rem;
	}

	.footer-links li {
		font-size: 14px;
		line-height: 20px;
	}

	.footer-grip-link.white-logo {
		margin-top: 1rem;
	}

	.copyright {
		flex-direction: column;
		align-items: center;
	}

	.wr-copyright-content {
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		padding-bottom: 1.15rem;
		flex-direction: column;
		align-items: center;
		font-family: Montserrat, sans-serif;
		text-align: center;
	}

	.footer-text {
		margin-right: 10px;
		margin-left: 10px;
		justify-content: center;
		text-align: center;
		letter-spacing: 0px;
	}

	.cb-static.footer-cb-left {
		width: 100%;
		align-items: center;
	}

	.footer-nav-list {
		margin-top: 0rem;
		margin-bottom: 0rem;
		margin-left: 0rem;
	}

	.footer-nav-item {
		margin-bottom: 0rem;
		text-align: center;
	}

	.card-block-wrapper {
		display: flex;
		justify-content: center;
	}

	._4cards-wrapper {
		max-width: none;
	}

	.logo-mobile {
		width: 150px;
		max-width: 175px;
		padding-left: 0px;
		background-color: transparent;
	}

	.online-banking-login-mobile-button {
		position: relative;
		left: 0px;
		top: 152px;
		right: 0px;
		z-index: 99;
		display: none;
		margin-top: 0rem;
		margin-left: 0px;
		padding: 0.75rem 3rem;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		flex: 0 0 auto;
		border-radius: 0px;
		background-image: linear-gradient(180deg, transparent, transparent);
		font-size: 0.9rem;
	}

	.online-banking-login-mobile-button-copy {
		position: static;
		left: 0px;
		top: 80px;
		right: 0px;
		z-index: 99;
		display: flex;
		margin-top: 0rem;
		margin-left: 0px;
		padding: 0.75rem 3rem;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		flex: 0 0 auto;
		border-radius: 0px;
		background-image: linear-gradient(180deg, transparent, transparent);
		font-size: 0.9rem;
	}

	.link-text-copy {
		opacity: 1;
		font-size: 0.725rem;
		line-height: 1rem;
		text-align: left;
	}

	.nav-menu-mob {
		position: absolute;
		left: 0%;
		top: 0%;
		right: 0%;
		bottom: auto;
		margin-top: 80px;
		justify-content: space-between;
	}

	.login-link {
		padding-right: 0px;
		padding-left: 0px;
		align-self: flex-start;
		text-align: left;
	}

	.div-block-128 {
		width: 60%;
		margin-right: 10px;
	}

	.div-block-129 {
		display: flex;
		padding-top: 0px;
		padding-left: 10px;
		flex-direction: column;
		justify-content: flex-start;
		flex-wrap: nowrap;
		align-items: flex-start;
		align-self: center;
		text-align: left;
	}

	._4cards-wrapper-2 {
		max-width: none;
	}

	.newsletter-form-2 {
		margin-bottom: 0px;
		flex-direction: column;
		align-items: center;
	}

	.subscribe-box {
		margin-top: 0px;
		padding-right: 31px;
		padding-left: 31px;
	}

	.heading-2 {
		font-size: 18px;
	}

	.heading-wrapper-2 {
		margin-bottom: 0rem;
	}

	.div-block-131 {
		max-width: 50px;
	}

	.newsletter-cb-wrapper-2 {
		padding-right: 0px;
	}

	.div-block-132 {
		padding-top: 0px;
	}

	.newsletter-text-field-2 {
		margin-right: 0px;
		text-align: center;
		width: 200px;
		margin-bottom: 15px;
	}

	.div-block-126-copy {
		padding-top: 0px;
	}

	.div-block-133 {
		justify-content: center;
	}

	.header {
		left: 0%;
		padding-right: 16px;
		padding-left: 16px;
		background-position: 50% 0%;
	}

	.div-block-140 {
		width: 100%;
		height: auto;
	}

	.paragraph-12 {
		font-size: 0.9rem;
	}

	.inside-content-beckground {
		padding-right: 16px;
		padding-left: 16px;
	}

	.text-block-21 {
		font-size: 0.8rem;
	}

	.inside-page-background-image {
		height: 100px;
	}

	.div-block-138 {
		background-position: 20% 50%;
	}

	.div-block-138.recent-blog-post {
		height: 250px;
	}

	.tertiary-button {
		margin-bottom: 10px;
		margin-left: 0px;
	}

	.tertiary-button.style {
		margin-top: 20px;
		margin-bottom: 0px;
	}

	.tertiary-button.style {
		margin-top: 20px;
		margin-bottom: 0px;
	}

	.link-5.breadcrumb-link {
		font-size: 0.8rem;
	}

	.button-styling {
		padding-left: 0px;
		flex-wrap: wrap;
	}

	.intro-paragraph-2 {
		line-height: 1.5rem;
	}

	.paragraph-14 {
		font-size: 0.9rem;
	}

	.content-container.threequarterwidth {
		padding-right: 0rem;
		padding-left: 0rem;
	}

	.secondary {
		display: flex;
		margin-top: 1rem;
		justify-content: center;
		font-size: 0.8rem;
	}

	.secondary-button {
		padding-left: 0px;
		flex-direction: row;
		justify-content: flex-start;
	}

	.right-sidebar-column {
		margin-top: 1rem;
		padding-top: 1rem;
	}

	.inside-content-beckground-2 {
		margin-top: 0px;
		padding-right: 20px;
		padding-left: 20px;
	}

	.text-block-22 {
		font-size: 0.8rem;
	}

	.wrapper {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
	}
}

#w-node-d3c7c57d-8027-87b3-6160-3a5c25df9c25-972e475a {
	-ms-grid-row: span 2;
	grid-row-start: span 2;
	-ms-grid-row-span: 2;
	grid-row-end: span 2;
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-column-align: center;
	justify-self: center;
	-ms-grid-row-align: center;
	align-self: center;
}

.nav-top-header-wrapper {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: end;
	justify-self: end;
	-ms-grid-row-align: end;
	align-self: end;
}

#w-node-d3c7c57d-8027-87b3-6160-3a5c25df9c10-972e475a {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

#w-node-fe50ce46-9e7c-dfb3-2514-fe6b4f1dbc4a-972e475a {
	-ms-grid-column: 3;
	grid-column-start: 3;
	-ms-grid-column-span: 1;
	grid-column-end: 4;
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-row-span: 2;
	grid-row-end: 3;
	-ms-grid-row-align: center;
	align-self: center;
}

#w-node-_8a32c861-8d62-2419-4206-168d0f4a941c-972e475a {
	-ms-grid-row-align: start;
	align-self: start;
}

#w-node-_8a32c861-8d62-2419-4206-168d0f4a9420-972e475a {
	-ms-grid-row-align: auto;
	align-self: auto;
}

#w-node-_6ddcee68-ce89-5a1b-e09e-59eb65ce083f-7930b016 {
	-ms-grid-row: span 2;
	grid-row-start: span 2;
	-ms-grid-row-span: 2;
	grid-row-end: span 2;
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-column-align: center;
	justify-self: center;
	-ms-grid-row-align: center;
	align-self: center;
}

#w-node-_6ddcee68-ce89-5a1b-e09e-59eb65ce0841-7930b016 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: end;
	justify-self: end;
	-ms-grid-row-align: end;
	align-self: end;
}

#w-node-_6ddcee68-ce89-5a1b-e09e-59eb65ce084e-7930b016 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

#w-node-_6ddcee68-ce89-5a1b-e09e-59eb65ce09cd-7930b016 {
	-ms-grid-column: 3;
	grid-column-start: 3;
	-ms-grid-column-span: 1;
	grid-column-end: 4;
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-row-span: 2;
	grid-row-end: 3;
	-ms-grid-row-align: center;
	align-self: center;
}

#w-node-_26502e83-cee7-4c41-2b3b-65c757824614-a2ac039f {
	-ms-grid-row: span 2;
	grid-row-start: span 2;
	-ms-grid-row-span: 2;
	grid-row-end: span 2;
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-column-align: end;
	justify-self: end;
	-ms-grid-row-align: center;
	align-self: center;
}

#w-node-_26502e83-cee7-4c41-2b3b-65c757824616-a2ac039f {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: end;
	justify-self: end;
	-ms-grid-row-align: end;
	align-self: end;
}

#w-node-_26502e83-cee7-4c41-2b3b-65c757824623-a2ac039f {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

#w-node-_26502e83-cee7-4c41-2b3b-65c7578247a2-a2ac039f {
	-ms-grid-column: 3;
	grid-column-start: 3;
	-ms-grid-column-span: 1;
	grid-column-end: 4;
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-row-span: 2;
	grid-row-end: 3;
	-ms-grid-row-align: center;
	align-self: center;
}

#w-node-_26502e83-cee7-4c41-2b3b-65c757824614-30a76d5b {
	-ms-grid-row: span 2;
	grid-row-start: span 2;
	-ms-grid-row-span: 2;
	grid-row-end: span 2;
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-column-align: end;
	justify-self: end;
	-ms-grid-row-align: center;
	align-self: center;
}

#w-node-_26502e83-cee7-4c41-2b3b-65c757824616-30a76d5b {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: end;
	justify-self: end;
	-ms-grid-row-align: end;
	align-self: end;
}

#w-node-_26502e83-cee7-4c41-2b3b-65c757824623-30a76d5b {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

#w-node-_26502e83-cee7-4c41-2b3b-65c7578247a2-30a76d5b {
	-ms-grid-column: 3;
	grid-column-start: 3;
	-ms-grid-column-span: 1;
	grid-column-end: 4;
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-row-span: 2;
	grid-row-end: 3;
	-ms-grid-row-align: center;
	align-self: center;
}

#w-node-_26502e83-cee7-4c41-2b3b-65c757824614-3b62e191 {
	-ms-grid-row: span 2;
	grid-row-start: span 2;
	-ms-grid-row-span: 2;
	grid-row-end: span 2;
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-column-align: end;
	justify-self: end;
	-ms-grid-row-align: center;
	align-self: center;
}

#w-node-_26502e83-cee7-4c41-2b3b-65c757824616-3b62e191 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
	-ms-grid-column-align: end;
	justify-self: end;
	-ms-grid-row-align: end;
	align-self: end;
}

#w-node-_26502e83-cee7-4c41-2b3b-65c757824623-3b62e191 {
	-ms-grid-column: span 1;
	grid-column-start: span 1;
	-ms-grid-column-span: 1;
	grid-column-end: span 1;
	-ms-grid-row: span 1;
	grid-row-start: span 1;
	-ms-grid-row-span: 1;
	grid-row-end: span 1;
}

#w-node-_26502e83-cee7-4c41-2b3b-65c7578247a2-3b62e191 {
	-ms-grid-column: 3;
	grid-column-start: 3;
	-ms-grid-column-span: 1;
	grid-column-end: 4;
	-ms-grid-row: 1;
	grid-row-start: 1;
	-ms-grid-row-span: 2;
	grid-row-end: 3;
	-ms-grid-row-align: center;
	align-self: center;
}

@media screen and (max-width: 991px) {
	#w-node-d3c7c57d-8027-87b3-6160-3a5c25df9c25-972e475a {
		-ms-grid-row-align: center;
		align-self: center;
		-ms-grid-column-align: center;
		justify-self: center;
		grid-row-start: span 1 !important;
		margin-top: 2px;
	}

	#w-node-d3c7c57d-8027-87b3-6160-3a5c25df9c10-972e475a {
		-ms-grid-column-span: 1;
		grid-column-end: 4;
		-ms-grid-column: 3;
		grid-column-start: 3;
		-ms-grid-row-span: 2;
		grid-row-end: 3;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-align: end;
		align-self: end;
	}

	#w-node-_54443ea0-ecac-5f3c-224a-0cbfdc79007e-972e475a {
		-ms-grid-column: span 2;
		grid-column-start: span 2;
		-ms-grid-column-span: 2;
		grid-column-end: span 2;
		-ms-grid-row: span 1;
		grid-row-start: span 1;
		-ms-grid-row-span: 1;
		grid-row-end: span 1;
	}

	#w-node-_6ddcee68-ce89-5a1b-e09e-59eb65ce083f-7930b016 {
		-ms-grid-row-align: center;
		align-self: center;
		-ms-grid-column-align: center;
		justify-self: center;
	}

	#w-node-_6ddcee68-ce89-5a1b-e09e-59eb65ce084e-7930b016 {
		-ms-grid-column-span: 1;
		grid-column-end: 4;
		-ms-grid-column: 3;
		grid-column-start: 3;
		-ms-grid-row-span: 2;
		grid-row-end: 3;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-align: end;
		align-self: end;
	}

	#w-node-_7355ce8e-d354-f586-0c60-dfe7c2514501-7930b016 {
		-ms-grid-column: span 2;
		grid-column-start: span 2;
		-ms-grid-column-span: 2;
		grid-column-end: span 2;
		-ms-grid-row: span 1;
		grid-row-start: span 1;
		-ms-grid-row-span: 1;
		grid-row-end: span 1;
	}

	#w-node-_26502e83-cee7-4c41-2b3b-65c757824614-a2ac039f {
		-ms-grid-row-align: center;
		align-self: center;
		-ms-grid-column-align: center;
		justify-self: center;
	}

	#w-node-_26502e83-cee7-4c41-2b3b-65c757824623-a2ac039f {
		-ms-grid-column-span: 1;
		grid-column-end: 4;
		-ms-grid-column: 3;
		grid-column-start: 3;
		-ms-grid-row-span: 2;
		grid-row-end: 3;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-align: end;
		align-self: end;
	}

	#w-node-_97906b6e-53fd-f630-1900-121d04a4c990-a2ac039f {
		-ms-grid-column: span 2;
		grid-column-start: span 2;
		-ms-grid-column-span: 2;
		grid-column-end: span 2;
		-ms-grid-row: span 1;
		grid-row-start: span 1;
		-ms-grid-row-span: 1;
		grid-row-end: span 1;
	}

	#w-node-_26502e83-cee7-4c41-2b3b-65c757824614-30a76d5b {
		-ms-grid-row-align: center;
		align-self: center;
		-ms-grid-column-align: center;
		justify-self: center;
	}

	#w-node-_26502e83-cee7-4c41-2b3b-65c757824623-30a76d5b {
		-ms-grid-column-span: 1;
		grid-column-end: 4;
		-ms-grid-column: 3;
		grid-column-start: 3;
		-ms-grid-row-span: 2;
		grid-row-end: 3;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-align: end;
		align-self: end;
	}

	#w-node-_458a198b-dbc2-1319-4709-17a2b7fe951d-30a76d5b {
		-ms-grid-column: span 2;
		grid-column-start: span 2;
		-ms-grid-column-span: 2;
		grid-column-end: span 2;
		-ms-grid-row: span 1;
		grid-row-start: span 1;
		-ms-grid-row-span: 1;
		grid-row-end: span 1;
	}

	#w-node-_26502e83-cee7-4c41-2b3b-65c757824614-3b62e191 {
		-ms-grid-row-align: center;
		align-self: center;
		-ms-grid-column-align: center;
		justify-self: center;
	}

	#w-node-_26502e83-cee7-4c41-2b3b-65c757824623-3b62e191 {
		-ms-grid-column-span: 1;
		grid-column-end: 4;
		-ms-grid-column: 3;
		grid-column-start: 3;
		-ms-grid-row-span: 2;
		grid-row-end: 3;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-align: end;
		align-self: end;
	}

	#w-node-da7d75a8-0b3d-b6e8-7262-560fab069579-3b62e191 {
		-ms-grid-column: span 2;
		grid-column-start: span 2;
		-ms-grid-column-span: 2;
		grid-column-end: span 2;
		-ms-grid-row: span 1;
		grid-row-start: span 1;
		-ms-grid-row-span: 1;
		grid-row-end: span 1;
	}
}

@media screen and (max-width: 767px) {
	#w-node-d3c7c57d-8027-87b3-6160-3a5c25df9c25-972e475a {
		-ms-grid-row: span 1;
		grid-row-start: span 1;
		-ms-grid-row-span: 1;
		grid-row-end: span 1;
		-ms-grid-column: span 1;
		grid-column-start: span 1;
		-ms-grid-column-span: 1;
		grid-column-end: span 1;
		-ms-grid-column-align: center;
		justify-self: center;
		-ms-grid-row-align: center;
		align-self: center;
		margin-top: -8px;
	}

	#w-node-d3c7c57d-8027-87b3-6160-3a5c25df9c10-972e475a {
		-ms-grid-column: 3;
		grid-column-start: 3;
		-ms-grid-column-span: 1;
		grid-column-end: 4;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row-align: auto;
		align-self: auto;
		-ms-grid-column-align: center;
		justify-self: center;
	}

	#w-node-_6ddcee68-ce89-5a1b-e09e-59eb65ce083f-7930b016 {
		-ms-grid-row: span 1;
		grid-row-start: span 1;
		-ms-grid-row-span: 1;
		grid-row-end: span 1;
		-ms-grid-column: span 1;
		grid-column-start: span 1;
		-ms-grid-column-span: 1;
		grid-column-end: span 1;
		-ms-grid-column-align: center;
		justify-self: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	#w-node-_6ddcee68-ce89-5a1b-e09e-59eb65ce084e-7930b016 {
		-ms-grid-column: 3;
		grid-column-start: 3;
		-ms-grid-column-span: 1;
		grid-column-end: 4;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row-align: auto;
		align-self: auto;
		-ms-grid-column-align: center;
		justify-self: center;
	}

	#w-node-_26502e83-cee7-4c41-2b3b-65c757824614-a2ac039f {
		-ms-grid-row: span 1;
		grid-row-start: span 1;
		-ms-grid-row-span: 1;
		grid-row-end: span 1;
		-ms-grid-column: span 1;
		grid-column-start: span 1;
		-ms-grid-column-span: 1;
		grid-column-end: span 1;
		-ms-grid-column-align: center;
		justify-self: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	#w-node-_26502e83-cee7-4c41-2b3b-65c757824623-a2ac039f {
		-ms-grid-column: 3;
		grid-column-start: 3;
		-ms-grid-column-span: 1;
		grid-column-end: 4;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row-align: center;
		align-self: center;
	}

	#w-node-_26502e83-cee7-4c41-2b3b-65c757824614-30a76d5b {
		-ms-grid-row: span 1;
		grid-row-start: span 1;
		-ms-grid-row-span: 1;
		grid-row-end: span 1;
		-ms-grid-column: span 1;
		grid-column-start: span 1;
		-ms-grid-column-span: 1;
		grid-column-end: span 1;
		-ms-grid-column-align: center;
		justify-self: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	#w-node-_26502e83-cee7-4c41-2b3b-65c757824623-30a76d5b {
		-ms-grid-column: 3;
		grid-column-start: 3;
		-ms-grid-column-span: 1;
		grid-column-end: 4;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row-align: center;
		align-self: center;
	}

	#w-node-_26502e83-cee7-4c41-2b3b-65c757824614-3b62e191 {
		-ms-grid-row: span 1;
		grid-row-start: span 1;
		-ms-grid-row-span: 1;
		grid-row-end: span 1;
		-ms-grid-column: span 1;
		grid-column-start: span 1;
		-ms-grid-column-span: 1;
		grid-column-end: span 1;
		-ms-grid-column-align: center;
		justify-self: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	#w-node-_26502e83-cee7-4c41-2b3b-65c757824623-3b62e191 {
		-ms-grid-column: 3;
		grid-column-start: 3;
		-ms-grid-column-span: 1;
		grid-column-end: 4;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row-align: center;
		align-self: center;
	}
}

@media screen and (max-width: 479px) {
	#w-node-d3c7c57d-8027-87b3-6160-3a5c25df9c25-972e475a {
		-ms-grid-column-span: 2;
		grid-column-end: 3;
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-column-align: start;
		justify-self: start;
		-ms-grid-row-align: end;
		margin-top: -6px;
	}

	#w-node-d3c7c57d-8027-87b3-6160-3a5c25df9c10-972e475a {
		-ms-grid-column: 3;
		grid-column-start: 3;
		-ms-grid-column-span: 1;
		grid-column-end: 4;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row-align: center;
		align-self: center;
	}

	#w-node-_54443ea0-ecac-5f3c-224a-0cbfdc79007e-972e475a {
		-ms-grid-column: span 1;
		grid-column-start: span 1;
		-ms-grid-column-span: 1;
		grid-column-end: span 1;
	}

	#w-node-_6ddcee68-ce89-5a1b-e09e-59eb65ce083f-7930b016 {
		-ms-grid-column-span: 2;
		grid-column-end: 3;
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-column-align: start;
		justify-self: start;
		-ms-grid-row-align: end;
		align-self: end;
	}

	#w-node-_6ddcee68-ce89-5a1b-e09e-59eb65ce084e-7930b016 {
		-ms-grid-column: 3;
		grid-column-start: 3;
		-ms-grid-column-span: 1;
		grid-column-end: 4;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row-align: center;
		align-self: center;
	}

	#w-node-_7355ce8e-d354-f586-0c60-dfe7c2514501-7930b016 {
		-ms-grid-column: span 1;
		grid-column-start: span 1;
		-ms-grid-column-span: 1;
		grid-column-end: span 1;
	}

	#w-node-_26502e83-cee7-4c41-2b3b-65c757824614-a2ac039f {
		-ms-grid-column-span: 2;
		grid-column-end: 3;
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-column-align: center;
		justify-self: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	#w-node-_26502e83-cee7-4c41-2b3b-65c757824623-a2ac039f {
		-ms-grid-column: 3;
		grid-column-start: 3;
		-ms-grid-column-span: 1;
		grid-column-end: 4;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row-align: center;
		align-self: center;
	}

	#w-node-_97906b6e-53fd-f630-1900-121d04a4c990-a2ac039f {
		-ms-grid-column: span 1;
		grid-column-start: span 1;
		-ms-grid-column-span: 1;
		grid-column-end: span 1;
	}

	#w-node-_26502e83-cee7-4c41-2b3b-65c757824614-30a76d5b {
		-ms-grid-column-span: 2;
		grid-column-end: 3;
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-column-align: center;
		justify-self: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	#w-node-_26502e83-cee7-4c41-2b3b-65c757824623-30a76d5b {
		-ms-grid-column: 3;
		grid-column-start: 3;
		-ms-grid-column-span: 1;
		grid-column-end: 4;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row-align: center;
		align-self: center;
	}

	#w-node-_458a198b-dbc2-1319-4709-17a2b7fe951d-30a76d5b {
		-ms-grid-column: span 1;
		grid-column-start: span 1;
		-ms-grid-column-span: 1;
		grid-column-end: span 1;
	}

	#w-node-_26502e83-cee7-4c41-2b3b-65c757824614-3b62e191 {
		-ms-grid-column-span: 2;
		grid-column-end: 3;
		-ms-grid-column: 1;
		grid-column-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-column-align: center;
		justify-self: center;
		-ms-grid-row-align: center;
		align-self: center;
	}

	#w-node-_26502e83-cee7-4c41-2b3b-65c757824623-3b62e191 {
		-ms-grid-column: 3;
		grid-column-start: 3;
		-ms-grid-column-span: 1;
		grid-column-end: 4;
		-ms-grid-row: 1;
		grid-row-start: 1;
		-ms-grid-row-span: 1;
		grid-row-end: 2;
		-ms-grid-row-align: center;
		align-self: center;
	}

	#w-node-da7d75a8-0b3d-b6e8-7262-560fab069579-3b62e191 {
		-ms-grid-column: span 1;
		grid-column-start: span 1;
		-ms-grid-column-span: 1;
		grid-column-end: span 1;
	}
}

/******************** Webflow ***********************/
/****************************************************/
@media (min-width: 992px) {
	html.w-mod-js:not(.w-mod-ix) [data-w-id="d3c7c57d-8027-87b3-6160-3a5c25df9c27"] {
		opacity: 0;
	}
}

@media screen and (max-width: 1300px) {
	.logo {
		left: 0;
	}

	.main-navbar-wrapper {
		padding-right: 0;
	}

	.nav-link-wrapper {
		padding: 0 0.3rem;
	}

	.w-nav[data-animation="over-right"] .w-nav-overlay,
	.w-nav[data-animation="over-right"] [data-nav-menu-open] {
		min-width: 100%;
	}
}

/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and (min-width: 991px) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and (min-width: 767px) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/***************** Print ***********************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/*********************** Header **********************/
/*****************************************************/
.submit-button {
	text-transform: uppercase;
}

.search {
	border-bottom: 5px solid transparent;
}

.nav-menu {
	position: static;
}

.search-bar-wrap {
	transition: all 500ms ease;
}

.search-bar-wrap.hide {
	opacity: 0 !important;
	transform: translate3d(0px, -80px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) !important;
	transform-style: preserve-3d !important;
}

.nav-menu {
	animation: fadeAnimation ease-out 1s;
	animation-iteration-count: 1;
	animation-fill-mode: forwards;
	opacity: 0;
}

@keyframes fadeAnimation {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.div-block-121 {
	animation: slideFromLeft ease-in-out 1s;
	transform-style: preserve-3d;
	width: 350px;
}

.div-block-121 .link-text {
	transition: none;
}

@keyframes slideFromLeft {
	0% {
		opacity: 0;
		transform: translate3d(-250px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
	}

	100% {
		opacity: 1;
		transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
	}
}

@media (max-width: 991px) {
	.online-banking-module {
		grid-row-start: auto !important;
		grid-row-end: auto !important;
		grid-column-start: auto !important;
		grid-column-end: auto !important;
		display: flex;
		position: absolute;
		width: 100%;
		max-width: none;
		top: 85px;
		right: auto;
		left: auto;
		padding-top: 7px;
		padding-bottom: 8px;
		padding-left: 6px;
		padding-right: 16px;
		align-items: center;
		justify-content: center;
		flex-direction: row;
		box-shadow: inset 0px 10px 8px -10px rgba(0, 0, 0, 0.3);
		background-color: #941114;
		height: 50px;
	}
}

@media (max-width: 767px) {
	.login-link {
		padding-right: 20px;
	}

	.login-links-container {
		display: flex;
		padding-top: 20px;
	}
}

@media (max-width: 479px) {
	.online-banking-module {
		margin: 0px;
	}

	.login-links-container {
		display: flex;
		padding-top: 0px;
		padding-left: 10px;
		flex-direction: column;
		justify-content: flex-start;
		flex-wrap: nowrap;
		align-items: flex-start;
		align-self: center;
		text-align: left;
	}

	.login-link {
		padding-right: 0;
	}

	.text-field {
		max-width: 163px;
	}
}

/****************** Accesible menu **********************/
/*****************************************************/
.accesible-navigation-menu {
	display: flex;
}

.accesible-navigation-menu ul {
	display: flex;
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

.accesible-navigation-menu ul .nav-item-li {
	padding: 20px 25px;
	margin-bottom: 0px;
	border-bottom: 3px solid transparent;
	display: flex;
	align-items: center;
	justify-content: center;
}

.accesible-navigation-menu ul .nav-item-li.hasChildren:after {
	font-family: 'webflow-icons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	content: "\e603";
	margin-left: 7px;
}

.accesible-navigation-menu ul .nav-item-li:hover {
	border-bottom-color: #941114;
}

.accesible-navigation-menu ul .nav-item-li a.nav-link {
	text-align: center;
}

.accesible-navigation-menu ul .nav-item-li:hover a.nav-link {
	color: #941114;
}

.accesible-navigation-menu li.nav-item-li .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: 0;
	transition: max-height 100ms ease 0s, opacity 200ms ease 0s;
	height: 0;
	margin-top: 0px;
	width: 100%;
	padding-left: 0px;
}

.accesible-navigation-menu li.nav-item-li .container-wrapper ul {
	display: none;
	width: 80%;
	min-width: auto;
	margin-right: auto;
	margin-left: auto;
	padding: 16px 32px;
	justify-content: left;
	align-items: center;
	background-color: #f2f2f2;
	flex-wrap: wrap;
}

.accesible-navigation-menu li.nav-item-li .container-wrapper ul li {
	display: flex;
	width: 11%;
	min-width: 150px;
	border-right: 1px solid rgba(82, 82, 82, 0.8);
	align-items: center;
	padding-top: 5px;
	padding-bottom: 5px;
	justify-content: center;
	margin-bottom: 3px;
	margin-top: 3px;
	position: relative;
	text-align: center;
	padding-left: 5px;
	padding-right: 7px;
}

.accesible-navigation-menu li.nav-item-li .container-wrapper ul li:nth-child(9n) {
	border-right: 1px solid transparent;
}

.accesible-navigation-menu li.nav-item-li .container-wrapper ul a,
.accesible-navigation-menu li.nav-item-li .container-wrapper ul span {
	color: #941114;
	font-size: 0.9rem;
	line-height: 1rem;
	font-weight: 700;
	text-decoration: none;
}

.accesible-navigation-menu li.nav-item-li .container-wrapper ul span.w--current,
.accesible-navigation-menu li.nav-item-li .container-wrapper ul span:hover,
.accesible-navigation-menu li.nav-item-li .container-wrapper ul a:hover,
.accesible-navigation-menu li.nav-item-li .container-wrapper ul a.w--current {
	color: #1c3d66;
}

.accesible-navigation-menu li.nav-item-li.open .container-wrapper,
.accesible-navigation-menu li.nav-item-li:hover .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
}

.accesible-navigation-menu li.nav-item-li.open .container-wrapper ul,
.accesible-navigation-menu li.nav-item-li:hover .container-wrapper ul {
	display: flex;
}

.accesible-navigation-menu li.nav-item-li ul li.hasChildren2:after {
	font-family: 'webflow-icons' !important;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	content: "\e603";
	margin-left: 7px;
}

.accesible-navigation-menu li.nav-item-li ul .container-wrapper-2 {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: 0;
	transition: max-height 100ms ease 0s, opacity 200ms ease 0s;
	height: 0;
}

.accesible-navigation-menu li.nav-item-li ul .container-wrapper-2 ul {
	display: none;
	padding: 0;
	background-color: #f4f4f4;
	margin: 0px;
	list-style-type: none;
	min-width: 220px;
	margin-top: 5px;
}

.accesible-navigation-menu li.nav-item-li ul .container-wrapper-2 ul li {
	border-right: 0px;
	width: 100%;
	margin: 0px;
	padding: 0px;
}

.accesible-navigation-menu li.nav-item-li ul .container-wrapper-2 ul li a {
	margin-bottom: 0rem;
	padding: 13px 20px;
	color: #333;
	font-size: 0.9rem;
	line-height: 0.95rem;
	font-weight: 600;
	text-align: left;
	text-decoration: none;
	display: inline-block;
	width: 100%;
}

.accesible-navigation-menu li.nav-item-li ul .container-wrapper-2 ul li a:hover,
.accesible-navigation-menu li.nav-item-li ul .container-wrapper-2 ul li a.w--current {
	background-color: #001e45;
	color: #fff;
}

.accesible-navigation-menu li.nav-item-li.open li:hover .container-wrapper-2,
.accesible-navigation-menu li.nav-item-li:hover li:hover .container-wrapper-2 {
	opacity: 1;
	max-height: 500px;
	height: auto;
}

.accesible-navigation-menu li.nav-item-li.open li:hover .container-wrapper-2 ul,
.accesible-navigation-menu li.nav-item-li:hover li:hover .container-wrapper-2 ul {
	display: block;
}

@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none !important;
	}
}

/****************** Mobile menu **********************/
/*****************************************************/
.mobile-navigation-menu {
	z-index: 2;
	-webkit-transform: translate3d(100%, 0, 0);
	transition: all 600ms ease;
	width: 100%;
	min-width: 100%;
	max-width: 100%;
	top: 85px;
	z-index: 200;
}

.mobile-navigation-menu.mm-menu_opened {
	transform: translate3d(0, 0, 0);
}

.mm-wrapper_opening .mm-menu_offcanvas.mm-menu_opened ~ .mm-slideout {
	transform: translate3d(0, 0, 0);
}

.mm-menu_offcanvas:not(.mm-menu_opened) {
	display: block;
}

@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-menu-button {
		display: none !important;
	}
}

@media screen and (min-width: 991px) {
	.mobile-navigation-menu li[data-breakpoints*="xl"] {
		display: flex !important;
	}
}

@media screen and (min-width: 767px) {
	.mobile-navigation-menu li[data-breakpoints*="md,lg"] {
		display: flex !important;
	}
}

.mobile-navigation-menu li[data-breakpoints] {
	display: none !important;
}

.mobile-navigation-menu li[data-breakpoints*="xs,sm"] {
	display: flex !important;
}

.mobile-navigation-menu > .mm-panels > .mm-panel:first-child > .mm-navbar {
	display: none;
}

.mobile-navigation-menu,
.mm-panels,
.mm-panels .mm-panel,
.mm-navbar {
	background-color: #941114;
	padding: 0;
}

.mobile-navigation-menu li a.nav-a {
	padding-top: 20px;
	padding-bottom: 20px;
	padding-left: 1.5rem;
	justify-content: center;
	color: #fff;
	font-size: 0.95rem;
	line-height: 1.15rem;
	font-weight: 500;
	letter-spacing: 0em;
	text-transform: none;
	align-items: flex-start;
	justify-content: flex-start;
	display: flex;
	align-items: center;
	justify-content: center;
}

.mobile-navigation-menu li a.nav-a.active,
.mobile-navigation-menu li a.nav-a:hover {
	background-color: #001e45;
	color: #fff;
}

.mm-listitem_selected > .mm-listitem__text {
	background-color: transparent;
}

.mm-listitem:after {
	left: 0;
}

.mm-listitem:after,
.mm-listitem__btn,
.mm-navbar {
	border-color: transparent;
}

.mm-listitem:after {
	border-color: hsla(0, 0%, 85.9%, 0.15);
}

.mm-listitem_opened > .mm-listitem__btn,
.mm-listitem_opened > .mm-panel {
	background-color: transparent;
}

.mm-btn_next:after,
.mm-btn_prev:before {
	border-color: #fff;
}

.mobile-navigation-menu li a.nav-a.has-children {
	padding-left: 72px;
}

.mm-listitem_vertical > .mm-listitem__btn {
	height: 58px;
}

.search-mobile-form {
	padding: 20px;
	width: 100%;
	display: flex;
}

.search-mobile-form input.search-input {
	padding: 15px;
	background-color: #fff;
	width: 100%;
}

.search-mobile-form .primary:hover {
	background-color: #001e45 !important;
}

/********************* Homepage *********************/
/*****************************************************/
.cbtext p {
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
	font-size: inherit;
}

.cbtext p:last-child {
	margin-bottom: 0px;
}

/*********************** Slideshow ********************/
/*****************************************************/
.slider-text p {
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-family: inherit;
	font-size: inherit;
}

.slideshow.homebox .slide-image {
	width: 100%;
	height: 100%;
	background-position: 50%;
	background-size: cover;
	position: absolute;
}

.slideshow.homebox .slide-2.w-slide {
	background: none;
}

@media screen and (max-width: 991px) and (min-width: 768px) {
	.slide-2 {
		background-position: 50% 50%;
		background-size: cover;
		background-repeat: no-repeat;
	}
}

@media screen and (max-width: 991px) {
	.slideshow.homebox {
		height: auto;
	}

	.slideshow.homebox .w-slider-mask {
		height: auto;
		overflow: visible;
	}

	.slideshow.homebox .slide-2.w-slide {
		height: auto;
	}

	.slideshow.homebox .w-slider-nav {
		padding-top: 20px;
	}

	.slideshow.homebox .slide-image {
		height: 350px;
		width: 100%;
		position: static;
	}

	.slideshow.homebox .hero-slide-overlay-copy {
		height: 350px;
	}

	.slideshow.homebox .slider-container {
		position: static;
		height: auto;
		display: block;
		margin: 0px;
		padding: 0px;
		width: 100%;
		max-width: 100%;
		top: 0;
	}

	.slideshow.homebox .slider-contents-wrapper {
		position: static;
		height: auto;
		padding: 0px;
		width: 100%;
		max-width: none;
	}

	.slideshow.homebox .slider-copy {
		width: 100%;
		background-color: transparent;
		-webkit-backdrop-filter: none;
		backdrop-filter: none;
		padding: 25px 50px;
		top: 0;
	}

	.slideshow.homebox .w-slider-arrow-left {
		left: -15px;
	}

	.slideshow.homebox .w-slider-arrow-right {
		right: -15px;
	}
}

@media screen and (max-width: 767px) {
	.slideshow.homebox .slide-image {
		height: 320px;
	}

	.slideshow.homebox .hero-slide-overlay-copy {
		height: 320px;
	}
}


@media screen and (max-width: 479px) {
	.slideshow.homebox .slide-image {
		height: 300px;
	}

	.slideshow.homebox .hero-slide-overlay-copy {
		height: 300px;
	}
}

/****************** Inside Page ******************/
/*************************************************/
@media screen and (max-width: 991px) {
	iframe[src*="youtube.com"] {
		aspect-ratio: 16 / 9;
		height: auto;
		max-width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

.breadcrumb-list li:last-child .breadcrumb-divider {
	display: none;
}

.breadcrumb-list li:last-child a {
	font-weight: 700;
	text-decoration: none;
	border-bottom: 1px solid transparent;
	font-family: Montserrat, sans-serif;
	color: #162b6d;
	font-size: 0.9rem;
	font-weight: 700;
	text-transform: capitalize;
}

.left-nav-list-link-2.active {
	background-color: rgba(211, 214, 216, 0.3);
	background-image: url('/20181016082423/assets/images/blue-arrow-icon-01_1.svg');
	background-position: 0% 50%;
	background-size: 12px;
	background-repeat: no-repeat;
	transform: translate(5px, 0px);
	color: #162b6d;
	text-decoration: none;
}

.left-nav-nested-list-link.grandchild.active {
	background-image: url('/20181016082423/assets/images/blue-arrow-icon-01_1.svg');
	background-position: 0% 50%;
	background-size: 12px;
	background-repeat: no-repeat;
	transform: translate(5px, 0px);
	color: #162b6d;
	background-color: rgba(211, 214, 216, 0.3);
}

.left-nav-nested-list-link.great-grandchild.active {
	background-color: rgba(211, 214, 216, 0.3);
	color: #162b6d;
}

.both-columns .breadcrumb-list,
.section-wrap.only-right .breadcrumb-list {
	margin-top: -7px;
}

/*************** Right Sidebar ********************/
/*************************************************/
.right-sidebar-column td img {
	max-width: none;
}

.right-sidebar-column .box {
	margin-bottom: 2.25rem;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	border-top: 1px none rgba(0, 0, 0, 0.15);
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.right-sidebar-column .box .content {
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.right-sidebar-column .box h4,
.right-sidebar-column .box h3 {
	margin-top: 0em;
	margin-bottom: 0.75em;
	font-family: Montserrat, sans-serif;
	font-weight: 400;
	font-size: 18px;
	line-height: 24px;
}

.right-sidebar-column .box .content p {
	font-size: 0.875rem;
	line-height: 1.35rem;
}

.right-sidebar-column .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-sidebar-column .box .contentbox_item_image {
	width: 24px;
}

.right-sidebar-column .box.documents td:first-child {
	padding-right: 7px;
}

.right-sidebar-column .box.tools td:first-child img {
	margin-right: 5px;
}

.right-sidebar-column a {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	font-family: Montserrat, sans-serif;
	color: #162b6d;
	font-weight: 400;
	text-decoration: none;
}

.right-sidebar-column a:hover {
	border-bottom-style: none;
	border-bottom-color: #549fc4;
}

/****************** Inside Page Styles ***************/
/*****************************************************/
.left-sidebar a,
.main-content-section a,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger,
.cbtext a,
.slider-text a {
	border-bottom: 1.5px solid rgba(92, 92, 92, 0.15);
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #162b6d;
	text-decoration: none;
}

.left-sidebar a:hover,
.main-content-section a:hover,
#cookie-consent-form-container form .consent-footer .consent-form-dismiss-trigger:hover,
.cbtext a:hover,
.slider-text a:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #162b6d;
}

h1 {
	font-family: Montserrat, sans-serif;
	font-weight: 700;
	font-size: 2.5rem;
}

h2 {
	margin-bottom: 5px;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-weight: 700;
	font-size: 2rem;
	line-height: 2rem;
}

h3 {
	margin-bottom: 5px;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-weight: 700;
	font-size: 1.5rem;
}

h4 {
	margin-bottom: 5px;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-weight: 700;
	font-size: 1.125rem;
}

h5 {
	margin-bottom: 5px;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-weight: 400;
	text-transform: uppercase;
	font-size: 0.875rem;
}

h6 {
	margin-bottom: 5px;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-weight: 400;
	text-transform: uppercase;
	font-size: 0.75rem;
}

h1 a {
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(92, 92, 92, 0.15);
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #162b6d;
	font-size: inherit;
	line-height: inherit;
	font-weight: 400;
	text-decoration: none;
}

h2 a {
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(92, 92, 92, 0.15);
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #162b6d;
	font-size: 2rem;
	line-height: 2.25rem;
	font-weight: 400;
	text-decoration: none;
}

@media screen and (max-width: 767px) {
	h2 a {
		font-size: 1.75rem;
	}
}

h3 a {
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(92, 92, 92, 0.15) !important;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #0915f8;
	font-size: 24px;
	line-height: 30px;
	font-weight: 400;
	text-decoration: none;
}

@media screen and (max-width: 767px) {
	h3 a {
		font-size: 1.5rem;
	}
}

h4 a {
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(92, 92, 92, 0.15);
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #162b6d;
	font-size: 18px;
	line-height: 24px;
	font-weight: 400;
	text-decoration: none;
}

@media screen and (max-width: 767px) {
	h4 a {
		font-size: 1.25rem;
	}
}

h5 a {
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(92, 92, 92, 0.15);
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #162b6d;
	font-size: 14px;
	line-height: 20px;
	font-weight: 400;
	text-decoration: none;
	text-transform: uppercase;
}

@media screen and (max-width: 767px) {
	h5 a {
		font-size: 1.125rem;
	}
}

h6 a {
	margin-bottom: 1rem;
	border-bottom: 1.5px solid rgba(92, 92, 92, 0.15);
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #162b6d;
	font-size: 12px;
	line-height: 18px;
	font-weight: 400;
	text-decoration: none;
	text-transform: uppercase;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	border-bottom-color: transparent;
	background-color: transparent;
	color: #162b6d;
}

p {
	margin-bottom: 1rem;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.5rem;
	text-decoration: none;
}

li {
	margin-bottom: 0;
	font-family: Montserrat, sans-serif;
	color: #333;
	font-size: 1rem;
	line-height: 1.5rem;
	text-decoration: none;
}

@media screen and (max-width: 479px) {
	p {
		font-size: 0.9rem;
	}

	li {
		font-size: 0.9rem;
	}
}

/* buttons */
.inside-page-container .button,
.inside-page-container a.primary,
.homeboxes a.primary,
.primary,
.inside-page-container button[type="submit"],
.inside-page-container .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
.ui-form-buttons .ui-form-button button,
.module-search button {
	display: inline-block;
	padding: 0.65rem 1rem;
	border-radius: 5px;
	background-color: #001e45 !important;
	transition: all 300ms ease, box-shadow 300ms ease, transform 300ms ease, background-color 300ms ease;
	color: #fff !important;
	font-size: 0.9rem;
	line-height: 0.95rem;
	font-weight: 600;
	letter-spacing: 0px;
	text-decoration: none;
	text-transform: none;
	border: 1px solid transparent;
	cursor: pointer;
}

.primary:hover,
.inside-page-container a.primary:hover .inside-page-container button[type="submit"]:hover,
.inside-page-container .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
.homeboxes a.primary:hover,
.ui-form-buttons .ui-form-button button:hover,
.module-search button:hover {
	background-color: #941114 !important;
	color: #fff !important;
	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.27);
}

.secondary,
.inside-page-container a.secondary,
.homeboxes a.secondary,
.inside-page-container button[type="submit"].secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.inside-page-container .cms_form_button.secondary,
.inside-page-container .button.secondary {
	display: inline-block;
	padding: 0.65rem 1rem;
	border-radius: 5px;
	background-color: transparent;
	opacity: 1;
	border: 1px solid #001e45 !important;
	transition: all 300ms ease, box-shadow 300ms ease, transform 300ms ease, background-color 300ms ease;
	color: #001e45 !important;
	font-size: 0.9rem;
	line-height: 0.95rem;
	font-weight: 600;
	letter-spacing: 0px;
	text-decoration: none;
	text-transform: none;
	border: 1px solid transparent;
}

.secondary:hover,
.inside-page-container a.secondary:hover,
.inside-page-container button[type="submit"].secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.inside-page-container .cms_form_button.secondary:hover,
.inside-page-container .button:hover,
.homeboxes a.secondary:hover {
	background-color: rgba(211, 214, 216, 0.5) !important;
	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.27);
	color: #001e45 !important;
}

.tertiary,
.inside-page-container button[type="submit"].tertiary,
.cms_form_button.tertiary,
.inside-page-container a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	display: inline-block;
	padding: 0.65rem 1rem;
	border-radius: 5px;
	background-color: transparent;
	opacity: 1;
	border: 1px solid #001e45 !important;
	transition: all 300ms ease, box-shadow 300ms ease, transform 300ms ease, background-color 300ms ease;
	color: #001e45 !important;
	font-size: 0.9rem;
	line-height: 0.95rem;
	font-weight: 600;
	letter-spacing: 0px;
	text-decoration: none;
	text-transform: none;
	border: 1px solid transparent;
}

.tertiary:hover,
.inside-page-container a.tertiary:hover,
.inside-page-container button[type="submit"].tertiary:hover,
.cms_form_button.tertiary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.homeboxes a.tertiary:hover {
	background-color: rgba(211, 214, 216, 0.5) !important;
	box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.27);
	color: #001e45 !important;
}

/* messages */
#message.success,
#polls .session_message.success {
	background-color: #98e4a3;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

#message.error,
#polls .session_message.error {
	background-color: #f3baba;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid white;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	background-color: #001e45;
	opacity: 100%;
	color: white;
}

table.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	background-color: #941114;
	opacity: 100%;
	color: white;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
}

table.styled th a:hover {
	color: #fff;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #d3d6d8;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.inside-row input[type="text"],
.inside-row input[type="tel"],
.inside-row input[type="password"],
.inside-row input[type="email"],
.inside-row select,
.inside-row textarea,
.inside-row input[type="search"] {
	max-width: 100%;
	padding: 8px 12px;
	margin-bottom: 5px;
	margin-top: 5px;
	font-size: 14px;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #666;
}

.inside-row textarea {
	resize: vertical;
}

.inside-row label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.inside-row input[type="text"],
	.inside-row input[type="tel"],
	.inside-row input[type="password"],
	.inside-row input[type="email"],
	.inside-row select,
	.inside-row textarea,
	.inside-row input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	margin-top: 10px;
	margin-bottom: 20px;
	border-left-color: #941114;
	background-color: transparent;
	font-family: Montserrat, sans-serif;
	color: #5b5b5b;
	font-size: 1.3rem;
	line-height: 1.75rem;
	font-weight: 500;
}

blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

@media screen and (max-width: 767px) {
	blockquote {
		font-size: 1.125rem;
		line-height: 1.5rem;
	}
}

/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
}

.pagination-wrapper .pagination-previous-page .disabled,
.pagination-wrapper .pagination-next-page .disabled {
	color: #333333;
	font-style: italic;
}

#pagination span {
	color: #333 !important;
}

/*********************** Footer **********************/
/*****************************************************/
#GRIPFooterLogo {
	line-height: 11px;
	padding-top: 0px !important;
}

.phone-mobile {
	display: none;
	color: #fff;
	text-decoration: none;
}

.phone-desktop {
	display: inline-block;
	text-decoration: none !important;
}

.privacy-links-container {
	width: 36vw;
}

.privacy-links-container p a {
	margin-right: 12px;
}

.privacy-links-container p a:last-child {
	margin-right: 0px;
}

@media (max-width: 991px) {
	.phone-desktop {
		display: none;
	}

	.phone-mobile {
		display: inline-block;
	}

	.paragraph-5.phone {
		margin-bottom: 8.5px;
	}

	.privacy-links-container {
		padding-left: 20px;
		padding-right: 20px;
		text-align: center;
	}
}

@media (max-width: 767px) {
	#GRIPFooterLogo {
		margin-left: auto;
		margin-right: auto;
	}

	.privacy-links-container {
		padding-left: 0;
		padding-right: 0;
		text-align: left;
		width: 31vw;
	}
}

@media (max-width: 479px) {
	#GRIPFooterLogo {
		margin-top: 1rem;
	}

	.privacy-links-container {
		text-align: center;
		width: 100%;
	}
}


/****************** Content Modules ****************/
/***************************************************/
/* ui form tags */
.ui-form-container {
	border: 0px;
}

.ui-form-container .ui-form-legend {
	background: #fff;
	margin-top: 1rem;
	margin-bottom: 0.5rem;
	color: #333;
	font-size: 1rem;
	line-height: 1.3rem;
	font-weight: bold;
	text-transform: none;
	padding: 0px;
	border-bottom: 0px;
}

.ui-form-fieldset .ui-form-legend {
	text-transform: none;
}

.ui-form-container .ui-form-fields {
	border-bottom: 0px;
}

.ui-form-container .ui-form-fields .ui-form-field.required .ui-form-label em,
.ui-form-container .required_information em {
	color: #ee0000;
	font-family: inherit;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices {
	border: 1px solid #cccccc;
	border-radius: 0px;
	padding: 8px 12px;
	box-shadow: none;
}

.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
	padding: 0px;
}

.ui-form-container .ui-form-fields .select2-container .select2-choice {
	box-shadow: none;
	border-radius: 0px;
	background: none;
	border: 1px solid #ccc;
	height: 38px !important;
}

.ui-form-buttons {
	padding: 0px;
	width: 100%;
	text-align: center;
}

.ui-form-container .ui-form-fields .ui-form-error em {
	color: #ee0000;
	display: block;
	font-size: 0.9rem;
	font-style: normal;
	line-height: normal;
	font-weight: 500;
	margin: 0px 0px 0px 0px;
}

.ui-element-with-example .source-code {
	overflow: hidden;
}

@media (max-width: 991px) {
	.ui-form-container .ui-form-fields {
		padding: 0px;
	}

	.ui-form-container .ui-form-label {
		position: static;
		padding: 0px;
		height: auto !important;
		display: block;
		width: 100%;
		left: 0px;
		text-align: left;
		top: auto;
		line-height: inherit;
	}

	.ui-form-container .ui-form-input {
		width: 100%;
	}

	.ui-form-container .ui-form-input input[type="text"],
	.ui-form-container .ui-form-input input[type="tel"],
	.ui-form-container .ui-form-input input[type="password"],
	.ui-form-container .ui-form-input input[type="email"],
	.ui-form-container .ui-form-input input[type="file"],
	.ui-form-container .ui-form-input select,
	.ui-form-container .ui-form-input textarea,
	.ui-form-container .ui-form-fields .select2-container,
	.ui-form-container .ui-form-fields .select2-container-multi,
	.ui-form-container .ui-form-fields .select2-container-multi .select2-choices .select2-search-field .select2-input {
		width: 100% !important;
		max-width: 100%;
	}
}

@media (max-width: 479px) {
	.ui-form-buttons button {
		margin-bottom: 10px;
	}
}

#ui-datepicker-div {
	font-size: 0.9rem;
}

/* search */
.search-collection > .search-node {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin: 1.5rem 0 0 0;
	padding: 1.5rem 0 0 0;
}

@media (max-width: 991px) {
	.module-search button {
		margin-top: 10px;
	}
}

/* accounts */
#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 243px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(92, 92, 92, 0.15);
	margin: 20px 0;
	padding-bottom: 4px;
	color: #333333;
	font-weight: bold;
}

#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url('/core/public/shared/assets/images/required.gif');
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* faq */
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(92, 92, 92, 0.15);
}

/* locations */
#locations_module.list .locations_list_title,
#locations_module.list .location_types_title {
	border-color: rgba(92, 92, 92, 0.15);
}

#locations_module.list .type_item .title,
#locations_module.list .type_item .title a {
	font-size: 1.5rem;
}

#locations_module.list .location-phone-row .location-item-phone-label {
	width: 100px;
}

/* resources */
.right-sidebar-column .box .moduleDocuments-rss-feed a {
	border-bottom: 0px;
}

/* staff */
#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
	width: 150px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 140px;
}

#staffdirectory_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(92, 92, 92, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}

/* submission form */
#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 0;
	font-weight: normal;
	font-size: 0.9rem;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

/* gdpr */
#cookie-consent-container.minimized {
	right: 210px;
	left: unset;
	bottom: 1.4rem;
}

#cookie-consent-container .consent-all-trigger,
#cookie-consent-container .consent-all-trigger:hover,
#cookie-consent-container .reject-all-trigger,
#cookie-consent-container .reject-all-trigger:hover {
	padding: 0 !important;
	background: none !important;
	color: #fff !important;
	border-bottom: 1px dotted #fff !important;
	font-size: 0.875rem !important;
	line-height: 20px !important;
}

#cookie-consent-form-container form .consent-header {
	background-color: #001e45;
}

#cookie-consent-form-container form .consent-header img {
	float: none;
	margin: 0 auto;
	text-align: center;
	display: block;
	max-width: 460px;
}

#cookie-consent-container {
	padding-right: 1.5rem;
	padding-bottom: 5rem;
	background: #001e45;
}

#cookie-consent-container.minimized {
	background: #262626;
}

#cookie-consent-form-container form {
	top: 13rem;
}

#cookie-consent-container .consent-actions a {
	font-weight: 600;
}

#cookie-consent-container .consent-actions a.consent-all-trigger,
#cookie-consent-container .consent-actions a.reject-all-trigger {
	border: 1px solid transparent;
}

#cookie-consent-form-container form {
	top: 8rem;
}

#cookie-consent-container .consent-disclaimer {
	padding-right: 5%;
	padding-left: 7%;
}

@media screen and (max-width: 1200px) {
	#cookie-consent-container .consent-disclaimer {
		width: 79%;
		padding-right: 5%;
		padding-left: 5%;
	}

	#cookie-consent-container .consent-actions {
		width: 21%;
	}
}

@media screen and (max-width: 991px) {
	#cookie-consent-form-container form {
		top: 5rem;
	}
}

@media screen and (max-width: 767px) {
	#cookie-consent-container .consent-disclaimer {
		width: 100%;
		padding-right: 0;
		padding-left: 0;
	}

	#cookie-consent-container .consent-actions {
		width: 100%;
	}
}

/****************** Content Boxes ******************/
/***************************************************/
/* submission forms */
#submissionforms_module.homepage_contentbox {
	text-align: left;
	line-height: 1.5rem;
	font-size: 1rem;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

/********************** Alert Box ******************/
/***************************************************/
.alert-wrap {
	display: none;
	position: fixed;
	z-index: 10000;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	background-color: rgba(0, 0, 0, 0.4);
}

.alert-wrap .alert-wrap-content {
	background-color: #ffff;
	margin: 200px auto;
	max-width: 650px;
	width: 80%;
	text-align: center;
	color: #fff;
	min-height: 200px;
	box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.75);
}

.alert-wrap .alert-wrap-content > div {
	padding: 30px 6vw;
}

.alert-wrap .alert-close-btn {
	padding: 0px;
	margin: 7px 7px 0px 0px;
	color: #fff;
	border: 0px;
	float: right;
	width: 25px;
	height: 25px;
	background-color: transparent;
	background-repeat: no-repeat;
	background-size: 25px;
	background-image: url('/assets/images/ic-close.png');
	background-position: center;
	cursor: pointer;
}


/****************** Text Size Widget ******************/
/***************************************************/
@media screen and (min-width: 992px) {
	html.text-large {
		font-size: 20px;
		font-size: 1.25rem;
	}

	html.text-medium {
		font-size: 18px;
		font-size: 1.125rem;
	}
}

.size-options {
	position: fixed;
	bottom: 20px;
	left: 10px;
	display: block;
	margin: 0 0;
	padding: 0.5em;
	background: #fff;
	-webkit-box-shadow: 3px 2px 15px #888888;
	box-shadow: 3px 2px 15px #888888;
	z-index: 99;
	list-style: none;
}

.size-options li {
	display: inline-block;
	font-size: 15px;
	line-height: 15px;
	margin: 0;
	vertical-align: middle;
	font-weight: 600;
	cursor: pointer;
}

.size-options li.title {
	color: #001e45;
	margin: 0px 10px 0px 4px;
	text-transform: uppercase;
	display: inline-block;
	font-size: 15px;
	line-height: 15px;
	vertical-align: middle;
	font-weight: 600;
	cursor: pointer;
}

.size-options li.backToTop {
	margin-left: 10px;
}

.size-options li.backToTop button {
	padding: 0px;
	font-size: 12px;
	line-height: 12px;
}

.size-options li.textResizer {
	margin: 0;
	display: inline-block;
	font-size: 15px;
	line-height: 15px;
	font-weight: bold;
}

.size-options li.textResizer button {
	background-color: transparent;
	padding: 0 2px;
	color: #6e6e6e;
}

.size-options li.textResizer button.active {
	color: #001e45;
}

.size-options li.textResizer button.smlFnt {
	font-size: 12px;
	line-height: 12px;
}

.size-options li.textResizer button.mdmFnt {
	font-size: 15px;
	line-height: 15px;
}

.size-options li.textResizer button.lrgFnt {
	font-size: 17px;
	line-height: 17px;
}

.size-options li img {
	display: block;
}

@media screen and (max-width: 991px) {
	.size-options {
		display: none;
	}
}

